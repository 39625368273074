import React, { useState, useEffect } from 'react';

// Utils
import { calculateTimeLeft } from '@myvote/common';

// Style
import { ExpiresText } from './style';

// TS Interfaces
import { TimeLeft, ITimer } from '@myvote/common';

const Timer = ({ data, text, className }: ITimer) => {
	const [timeLeft, setTimeLeft] = useState<TimeLeft[]>([]);

	useEffect(() => {
		// Check if compoents is already mounted to prevent memory leak
		let isMounted = true;

		let date = new Date();
		setTimeout(() => {
			if (isMounted) setTimeLeft(calculateTimeLeft(data, date));
		}, 1000);

		return () => {
			isMounted = false;
		};
	}, [data, timeLeft]);

	return (
		<ExpiresText className={className}>
			{text && <p>{text}</p>}{' '}
			{timeLeft.length !== 0 && (
				<p className='time'>
					- {timeLeft[0].days ? timeLeft[0].days + 'd' : ''}{' '}
					{timeLeft[0].hours ? timeLeft[0].hours + 'h' : ''}{' '}
					{timeLeft[0].minutes ? timeLeft[0].minutes + 'm' : ''}{' '}
					{timeLeft[0].seconds ? timeLeft[0].seconds + 's' : ''} remaining
				</p>
			)}
		</ExpiresText>
	);
};

export default Timer;
