import React from 'react';
import {
	Route,
	Redirect,
	RouteComponentProps,
	RouteChildrenProps,
} from 'react-router-dom';

import { isEmpty } from '@myvote/common';

// Grapqhl
import { useQuery } from '@apollo/client';
import { ME } from '@myvote/common';

// Redux
import { connect } from 'react-redux';
import { setUser } from '@myvote/common';

// TS Interfaces
import { IUser } from '@myvote/common';

interface RouteProps {
	// location?: H.Location;
	component?:
		| React.ComponentType<RouteComponentProps<any>>
		| React.ComponentType<any>
		| any
		| void;
	render?: (props: RouteComponentProps<any>) => React.ReactNode;
	children?:
		| ((props: RouteChildrenProps<any>) => React.ReactNode)
		| React.ReactNode;
	path?: string | string[];
	exact?: boolean;
	sensitive?: boolean;
	strict?: boolean;
	setUser?: any;
	admin?: boolean;
	user: any;
}

interface Me {
	me: any;
}

const checkAuth = (me: Me, user: any, setUser: any) => {
	//Check if cookie exists
	if (document.cookie.match(/^(.*;)?\s*sid\s*=\s*[^;]+(.*)?$/) !== null) {
		// Set user info
		if (isEmpty(user)) {
			if (me.me) {
				setUser(me.me);
			}
		}
		return true;
	}

	return false;
};

const PrivateRoute: React.FC<RouteProps> = ({
	component: Component,
	children,
	setUser,
	user,
	admin,
	...rest
}) => {
	const { loading, data: me } = useQuery(ME);

	// TODO: resturn null like in App
	if (loading) return null;

	return (
		<Route
			{...rest}
			render={(props) =>
				checkAuth(me, user, setUser) ? (
					(() => {
						//TODO:Check admin on server
						if (admin && me.me.accountType === 'voter') {
							return (
								<Redirect
									to={{
										pathname: '/polls-list',
									}}
								/>
							);
						} else {
							return <Component {...props} />;
						}
					})()
				) : (
					<Redirect
						to={{
							pathname: '/',
						}}
					/>
				)
			}
		/>
	);
};

const mapStateToProps = (state: IUser) => ({
	user: state.user,
});

const mapDispatchToProps = {
	setUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
