import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// GraphQl
import { useMutation } from '@apollo/client';
import { PASS_CHANGE } from '@myvote/common';

// Utils
import { isJson } from '@myvote/common';

// Components
import { Input, Button } from '../../../../shared/components';

// Assets
import logo from '../../../../assets/myvote-logo.svg';

// Styles
import { MainWrap, InnerWrap, Text } from './style';

// TS Interfaces
export interface IResetData {
	password: string;
	showPassword: boolean;
	passwordConfirmation: string;
	showPasswordC: boolean;
}

export interface IError {
	emailText?: string;
	passwordText?: string;
	passwordTextC?: string;
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const ResetPassword = () => {
	const history = useHistory();
	let query = useQuery();
	const resetId = query.get('id');
	const token = query.get('token');

	const [forgotPasswordChange] = useMutation(PASS_CHANGE);

	const [resetData, setResetData] = useState<IResetData>({
		password: '',
		showPassword: false,
		passwordConfirmation: '',
		showPasswordC: false,
	});

	// Error handling state
	const [errorState, setError] = useState<IError>({
		emailText: '',
		passwordText: '',
		passwordTextC: '',
	});

	const [success, setSuccess] = useState<boolean>(false);

	return (
		<MainWrap>
			<InnerWrap>
				<img src={logo} alt='' style={{ marginBottom: 24 }} />
				<Text>
					<h1>{!success ? 'Reset your password' : 'Success!'}</h1>
					<p>
						{!success
							? 'Enter a new password below.'
							: 'You’ve changed your password successfully.'}
					</p>
				</Text>
				{!success ? (
					<form
						noValidate
						onSubmit={(e: React.ChangeEvent<HTMLFormElement>) => {
							e.preventDefault();
							const { password, passwordConfirmation } = resetData;

							forgotPasswordChange({
								variables: {
									password,
									passwordConfirmation,
									id: resetId,
									token,
								},
							}).then(
								() => {
									setSuccess(true);
								},
								(error: any) => {
									error.graphQLErrors.map(({ message }: any) => {
										if (isJson(message)) {
											const { error } = JSON.parse(message);
											try {
												setError({
													passwordText: '',
													passwordTextC: '',
												});

												error.map((val: any) => {
													let key = val.context.key;

													if (key === 'password') {
														setError((errorState: IError) => ({
															...errorState,
															passwordText: val.message,
														}));
													}

													if (key === 'passwordConfirmation') {
														setError((errorState: IError) => ({
															...errorState,
															passwordTextC: 'Passwords do not match',
														}));
													}
													return null;
												});
											} catch (e) {
												console.log(e);
											}
										} else {
											setError((errorState: IError) => ({
												...errorState,
												passwordTextC: message,
											}));
										}
										return null;
									});
								}
							);
						}}
					>
						<Input
							id='new-password'
							label='New Password'
							name='new-password'
							type={resetData.showPassword ? 'text' : 'password'}
							autoComplete='current-password'
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setResetData({
									...resetData,
									password: e.target.value,
								})
							}
							value={resetData.password}
							required
							error={errorState.passwordText !== '' ? true : false}
							errorText={errorState.passwordText}
						/>
						<Input
							id='confirmPassword'
							label='Confirm Password'
							name='confirmPassword'
							type={resetData.showPasswordC ? 'text' : 'password'}
							autoComplete='current-password'
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setResetData({
									...resetData,
									passwordConfirmation: e.target.value,
								})
							}
							value={resetData.passwordConfirmation}
							required
							error={errorState.passwordTextC !== '' ? true : false}
							errorText={errorState.passwordTextC}
						/>
						<Button
							type='submit'
							text='Next'
							label={'Submit'}
							style={{ marginTop: 12 }}
						/>
					</form>
				) : (
					<button
						className='back'
						// disabled={!email ? true : false}
						onClick={() => history.push('/')}
					>
						Back to sign in
					</button>
				)}
			</InnerWrap>
		</MainWrap>
	);
};

export default ResetPassword;
