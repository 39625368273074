import React from 'react';

// Components
import Dashboard from './Dashboard';

const Index = () => {
	return <Dashboard />;
};

export default Index;
