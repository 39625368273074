import React from 'react';

// Notifications
import { toast } from 'react-toastify';

// Redux
import { connect } from 'react-redux';
import { logOutUser } from '@myvote/common';

// Utils
import { isJson } from '@myvote/common';

// Modal
import Modal from 'react-modal';

// Components
import CmsLayout from '../../../general/CmsLayout';
import { Loader, Input } from '../../../../shared/components';

// Stles
import {
	Wrapper,
	Or,
	Heading,
	Email,
	BWrap,
	PasswordWrap,
	Warrning,
	DeleteBtn,
	SaveBtn,
	Flex,
	BtnWrap,
	ModalStyles,
	MobileStyles,
} from './style';
import { color } from '../../../../shared/utils/styles';

// TS Interfaces
import { IUpdate, IError } from './';
interface IProfile {
	data: any;
	hooks: {
		errorState: IError;
		setError: any;
		email: IUpdate;
		setEmail: any;
		password: IUpdate;
		setPassword: any;
		modalIsOpen: boolean;
		setIsOpen: any;
	};
	mutations: any;
	helpers: any;
	routes: any;
}

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const Profile = (props: IProfile) => {
	const { data } = props.data;
	const { refetchMe, updateUser, deleteUser } = props.mutations;
	const {
		errorState,
		setError,
		email,
		setEmail,
		password,
		setPassword,
		modalIsOpen,
		setIsOpen,
	} = props.hooks;
	const { closeModal } = props.helpers;
	const { history } = props.routes;

	const media = window.matchMedia('(max-width: 525px)').matches;

	return (
		<CmsLayout>
			<Wrapper>
				{data ? (
					<React.Fragment>
						<Heading>Account Settings</Heading>
						<Email>{data.me.email}</Email>
						<BWrap>
							<form
								noValidate
								onSubmit={(e) => {
									e.preventDefault();
									const { password: mainPassword, passwordC } = password;

									updateUser({
										variables: {
											id: data.me.id,
											email: email.email,
											password: mainPassword,
											passwordConfirmation: passwordC,
										},
									})
										.then(() => {
											setError((errorState: IError) => ({
												...errorState,
												emailText: '',
												passwordText: '',
												passwordCText: '',
												passwordTextD: '',
											}));
											toast.success('Success', {
												toastId: 'p-success',
											});
											refetchMe();
										})
										.catch((error: any) => {
											error.graphQLErrors.map(({ message }: any) => {
												if (isJson(message)) {
													try {
														const { error } = JSON.parse(message);
														setError((errorState: IError) => ({
															...errorState,
															emailText: '',
															passwordText: '',
														}));

														error.map((val: any) => {
															if (val.context.key === 'email') {
																setError((errorState: IError) => ({
																	...errorState,
																	emailText: val.message,
																}));
															}
															if (val.context.key === 'password') {
																setError((errorState: IError) => ({
																	...errorState,
																	passwordText: val.message,
																}));
															}

															if (val.context.key === 'passwordConfirmation') {
																setError((errorState: IError) => ({
																	...errorState,
																	passwordCText: 'Passwords do not match',
																}));
															}
															return null;
														});
													} catch (e) {
														setError({
															...errorState,
															passwordText: message,
														});
													}
												} else {
													setError((errorState: IError) => ({
														...errorState,
														emailText: message,
													}));
												}
												return null;
											});
										});
								}}
							>
								<Input
									id='email'
									name='email'
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										setEmail({
											...email,
											email: e.target.value,
										})
									}
									value={email.email}
									placeholder='Enter new email'
									basic
									borderColor={errorState.emailText && color.danger}
									error={errorState.emailText !== '' ? true : false}
									errorText={errorState.emailText}
								/>
								<Or>
									<span />
									<p>or</p>
									<span />
								</Or>
								<PasswordWrap>
									<Input
										id='password'
										label='Change password'
										name='password'
										type={password.showPassword ? 'text' : 'password'}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
											setPassword({
												...password,
												password: e.target.value,
											})
										}
										value={password.password}
										placeholder='Password'
										borderColor={errorState.passwordText && color.danger}
										error={errorState.passwordText !== '' ? true : false}
										errorText={errorState.passwordText}
									/>

									<Input
										id='password2'
										label='Confirm password'
										name='password2'
										type={password.showPasswordC ? 'text' : 'password'}
										className='confirm'
										onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
											setPassword({
												...password,
												passwordC: e.target.value,
											})
										}
										value={password.passwordC}
										placeholder='Confirm Password'
										borderColor={errorState.passwordCText && color.danger}
										error={errorState.passwordCText !== '' ? true : false}
										errorText={errorState.passwordCText}
									/>
								</PasswordWrap>

								<Warrning>
									Minimum 8 characters, at least one uppercase letter and one
									digit
								</Warrning>

								<DeleteBtn type='button' onClick={() => setIsOpen(true)}>
									Delete account
								</DeleteBtn>

								<Modal
									isOpen={modalIsOpen}
									onRequestClose={closeModal}
									style={media ? MobileStyles : ModalStyles}
									contentLabel='Modal'
								>
									<Flex>
										<h2>Are you sure you want to delete your account?</h2>
										<p>
											After deleting your account, you will never be able to
											retrieve it.
										</p>
										<Input
											id='password3'
											label='Enter your password to confirm'
											name='password3'
											onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
												setPassword({
													...password,
													passwordDelete: e.target.value,
												})
											}
											value={password.passwordDelete}
											placeholder='Password'
											basic
											error={errorState.passwordTextD !== '' ? true : false}
											errorText={errorState.passwordTextD}
										/>
										<BtnWrap>
											<button
												className='outline'
												type='button'
												onClick={() => {
													setIsOpen(false);
													setError({
														...errorState,
														passwordTextD: '',
													});
												}}
											>
												Cancel
											</button>

											<button
												type='button'
												onClick={() => {
													deleteUser({
														variables: {
															id: data.me.id,
															password: password.passwordDelete,
														},
													})
														.then(() => {
															document.cookie =
																'sid=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
															logOutUser();
															history.push('/');
														})
														.catch((e: any) => {
															e.graphQLErrors.map(({ message }: any) => {
																setError({
																	...errorState,
																	passwordTextD: message,
																});
																return null;
															});
														});
												}}
											>
												Yes, delete it
											</button>
										</BtnWrap>
									</Flex>
								</Modal>
								<SaveBtn
									disabled={
										email.email === '' && password.password === ''
											? true
											: false
									}
									type='submit'
								>
									Save
								</SaveBtn>
							</form>
						</BWrap>
					</React.Fragment>
				) : (
					<Loader />
				)}
			</Wrapper>
		</CmsLayout>
	);
};

const mapDispatchToProps = {
	logOutUser,
};

export default connect(null, mapDispatchToProps)(Profile);
