import styled from 'styled-components';
import { color } from '../../utils/styles';

export const IconWrap = styled.div`
	display: flex;
	padding: 13px 0;
	align-items: center;

	svg path.b {
		fill: ${color.base};
	}

	svg,
	img {
		width: 24px;
		height: 24px;
	}

	& > div {
		margin-left: 10px;
		color: inherit;
		font-size: inherit;
	}
`;

export const Btn = styled.button`
	display: flex;
	align-items: center;
	width: 100%;
	padding: 13px 0;

	svg path.b {
		fill: ${color.base};
	}

	& > div {
		margin-left: 10px;
	}
`;
