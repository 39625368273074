import styled from 'styled-components';

export const FilterWrap = styled.div`
	display: flex;
	align-items: center;

	button {
		color: #202124;
		padding: 0;
		border: 0;
		outline: 0;
		box-shadow: 0px 2px 4px #0000000f;
		text-transform: capitalize;
		letter-spacing: 0;
		padding: 7px 8px;
		margin-right: 14px;
		cursor: pointer;

		&.is-active {
			color: #0040bf;
		}
	}

	@media (max-width: 525px) {
		order: 1;

		button {
			font-size: 14px;
		}
	}
`;
