import React from 'react';

// Components
import Header from './Header';

const Index = ({ hook }: any) => {
	return <Header setOpenMenu={hook} />;
};

export default Index;
