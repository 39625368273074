import styled from 'styled-components';

export const ModalStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		transform: 'translate(-50%, -50%)',
		fontSize: '16px',
		lineHeight: '24px',
		padding: '50px 40px',
		maxWidth: '750px',
		width: '90%',
		maxHeight: '540px',
	},
};

export const Btn = styled.button`
	position: absolute;
	top: 22px;
	right: 12px;

	@media (max-width: 525px) {
		top: 12px;
		right: 6px;
	}
`;

export const Image = styled.img`
	width: 16px;

	@media (max-width: 525px) {
		width: 12px;
	}
`;
