import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Styles
import { Wrap, InnerWrap, TextWrap, Btn } from './style';

// Assets
import logo from '../../../../assets/myvote-logo.svg';
import votingIcon from '../../../../assets/voting.svg';

const Index = () => {
	const history = useHistory();

	useEffect(() => {
		if (document.cookie.indexOf('welcome=') !== -1) {
			history.push('/polls-list');
		}
	}, [history]);

	const handleWelcome = () => {
		document.cookie = 'welcome=true';
		history.push('/polls-list');
	};

	return (
		<Wrap>
			<InnerWrap>
				<img src={logo} alt='' style={{ marginBottom: 48 }} />
				<img src={votingIcon} alt='' style={{ marginBottom: 48 }} />
				<TextWrap>
					<h1>You are ready to start voting!</h1>
					<p>All your polls in one place, instantly accessible.</p>
				</TextWrap>
				<Btn onClick={handleWelcome}>Proceed</Btn>
			</InnerWrap>
		</Wrap>
	);
};

export default Index;
