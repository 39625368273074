import styled from 'styled-components';
import { font, color } from '../../../../shared/utils/styles';

export const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
`;

export const PollWrap = styled.div`
	width: 100%;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	display: flex;
	height: 100%;
	position: relative;

	.not-found {
		padding-right: 128px;
		margin-top: 100px;

		@media (pointer: coarse), (max-width: 1160px) {
			padding-right: 32px;
		}

		@media (max-width: 950px) {
			padding-right: 0;
		}
	}
`;

export const Overlay = styled.div`
	pointer-events: none;
	width: calc(100% + 115px);
	height: 86px;
	position: absolute;
	left: -128px;
	bottom: 0;
	background-color: ${color.background};
	background: linear-gradient(
		180deg,
		rgba(2, 0, 36, 0) 0%,
		rgba(250, 250, 250, 1) 100%
	);

	opacity: 0.9;
`;

export const TopBar = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding-right: 128px;
	margin-bottom: 20px;

	input {
		background-color: #eeeeed;
		border: none;
		width: 335px;
		box-shadow: none;
		height: 43px;
		border-radius: 23px;
		padding: 0 16px;
		font-size: 1rem;

		&:focus {
			border: none;
			outline: none;
		}
	}

	@media (pointer: coarse), (max-width: 1160px) {
		padding-right: 32px;
	}

	@media (max-width: 950px) {
		padding-right: 0;
	}

	@media (max-width: 525px) {
		flex-direction: column;
	}
`;

export const PollList = styled.div`
	width: 100%;
	height: calc(100vh - 43px - 32px - 64px);
	color: #202124;
	padding: 0;
	padding-right: 128px;
	overflow-y: auto;
	padding-bottom: 50px;

	hr:last-of-type {
		display: none;
	}

	@media (pointer: coarse), (max-width: 1160px) {
		padding-right: 32px;
	}

	@media (max-width: 950px) {
		padding-right: 0;
	}

	@media (max-width: 525px) {
		padding-right: 24px;
		position: relative;
		margin-right: -24px;
		width: calc(100% + 24px);
	}
`;

export const PollLinkWrap = styled.div`
	position: relative;
	margin-bottom: 24px;
	width: calc(100% + 13px);
	display: flex;
	position: relative;
	box-sizing: border-box;
	text-align: left;
	align-items: center;
	justify-content: flex-start;
	text-decoration: none;
	transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	box-shadow: 0px 3px 6px #0000000f;
	background-color: ${color.white};

	&:hover {
		text-decoration: none;
		background-color: rgba(0, 0, 0, 0.02);
		opacity: 1;
	}

	&.disabled-link {
		opacity: 0.7;
		cursor: not-allowed;

		a {
			opacity: 0.4;
			pointer-events: none;
			cursor: not-allowed;
		}
	}

	.open-icon {
		width: 60px;
		height: 100%;
		justify-content: center;
	}

	button {
		height: 100%;
		width: 95px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		svg {
			width: 30px;
			height: 30px;
		}

		&.danger {
			&:hover {
				svg path {
					fill: ${color.danger};
				}
			}
		}
	}

	@media (max-width: 525px) {
		width: 100%;
		max-width: calc(81% - 24px);
	}
`;

export const PollLink = styled.a`
	flex: 1;
	display: flex;
	align-items: center;
	padding: 16px;
	min-height: 101px;
	cursor: pointer;

	&:hover {
		opacity: 1;
	}

	@media (max-width: 525px) {
		width: 100%;
		max-width: 80%;

		& > div {
			max-width: 100%;

			h2 {
				max-width: 100%;
			}
		}
	}
`;

export const TitleWrap = styled.div`
	flex: 1;

	span {
		font-size: 26px;
	}

	h2 {
		font-size: 26px;
		line-height: 34px;
		${font.medium};
		max-width: 90%;
		overflow-wrap: break-word;
		word-break: break-all;
	}

	p {
		font-size: 12px;
		text-transform: uppercase;

		&:first-of-type {
			margin-right: 5px;
		}

		&:last-of-type {
			margin-left: 5px;
		}
	}

	.s-result {
		font-size: 12px;
		color: #202124;
		text-transform: capitalize;
	}

	.b-wrap {
		display: flex;
		align-items: center;

		p {
			margin-left: 0;
		}
	}

	@media (max-width: 525px) {
		h2 {
			font-size: 24px;
			margin-bottom: 14px;
		}
	}
`;

export const VNumbers = styled.span`
	position: absolute;
	top: 49%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export const ExpiresText = styled.div`
	opacity: 1 !important;
	display: flex;
	font-size: 12px;
	min-height: 16px;

	.time {
		margin-left: 5px;
		text-transform: lowercase !important;
	}

	&.published {
		p:first-of-type {
			color: ${color.success};
		}
	}

	&.finished {
		p:first-of-type {
			color: ${color.primary};
		}
	}
`;
