import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Grapqhl
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_USER, DELETE_USER, ME } from '@myvote/common';

// Components
import Profile from './Profile';

// TS Interfaces
export interface IUpdate {
	reveal?: boolean;
	email?: string;
	password?: string;
	passwordC?: string;
	showPassword?: boolean;
	showPasswordC?: boolean;
	passwordDelete?: string;
}

export interface IError {
	emailText?: string;
	passwordText?: string;
	passwordCText?: string;
	passwordTextD?: string;
}

const Index = () => {
	const history = useHistory();
	const { data, refetch: refetchMe } = useQuery(ME);

	// Error handling
	const [errorState, setError] = useState<IError>({
		emailText: '',
		passwordText: '',
		passwordCText: '',
		passwordTextD: '',
	});

	// Change email
	const [email, setEmail] = useState<IUpdate>({
		email: '',
	});

	// Change password
	const [password, setPassword] = useState<IUpdate>({
		password: '',
		passwordC: '',
		showPassword: false,
		showPasswordC: false,
		passwordDelete: '',
	});

	const [updateUser] = useMutation(UPDATE_USER);
	const [deleteUser] = useMutation(DELETE_USER);

	// Modal
	const [modalIsOpen, setIsOpen] = useState(false);
	// Close modal
	const closeModal = () => {
		setIsOpen(false);
		setError({
			...errorState,
			passwordTextD: '',
		});
	};

	return (
		<Profile
			data={{ data }}
			mutations={{ refetchMe, updateUser, deleteUser }}
			hooks={{
				errorState,
				setError,
				email,
				setEmail,
				password,
				setPassword,
				modalIsOpen,
				setIsOpen,
			}}
			helpers={{ closeModal }}
			routes={{ history }}
		/>
	);
};

export default Index;
