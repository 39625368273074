import styled from 'styled-components';

export const LoaderWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-right: 128px;
	margin-top: 10vh;

	img {
		width: 80px;
		position: relative;
	}

	@media (pointer: coarse), (max-width: 1160px) {
		padding-right: 32px;
	}

	@media (max-width: 950px) {
		padding-right: 0;
	}
`;
