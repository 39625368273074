import styled from 'styled-components';
import { font, color } from '../../../../shared/utils/styles';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	form {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		max-width: 440px;

		@media (max-width: 525px) {
			max-width: 100%;
			width: 100%;
		}
	}
`;

export const Or = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;

	span {
		border-top: 1px solid ${color.line};
		width: 100%;
	}

	p {
		margin: 0 30px;
	}
`;

export const Heading = styled.h2`
	${font.medium};
	margin-bottom: 32px;

	@media (max-width: 950px) {
		display: none;
	}
`;

export const Email = styled.p`
	${font.medium};
	font-size: 13px;
	margin-bottom: 32px;

	@media (max-width: 525px) {
		margin-bottom: 24px;
	}
`;

export const BWrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	#email {
		padding: 0 0 0 14px;
		height: 45px;
	}
`;

export const PasswordWrap = styled.div`
	display: flex;
	/* align-items: flex-end; */

	& > div {
		margin: 0;
	}

	.confirm {
		margin-left: 16px;
	}

	label {
		top: -8px;
	}

	input {
		padding: 0 0 0 14px;
	}

	@media (max-width: 525px) {
		flex-direction: column;
		width: 100%;

		.confirm {
			margin-top: 24px;
			margin-left: 0;
		}
	}
`;

export const Warrning = styled.p`
	margin-top: 10px;
	margin-bottom: 25px;
	font-size: 12px;
	max-width: 280px;
`;

export const DeleteBtn = styled.button`
	color: ${color.danger};
	padding: 0;
	font: inherit;
	font-size: 16px;
	letter-spacing: 0;
	text-decoration: underline;
	margin-bottom: 48px;
`;

export const SaveBtn = styled.button`
	background-color: ${color.primary};
	padding: 12px 32px;
	color: ${color.white};
	border-radius: 27px;
	margin-right: 16px;
	transition: opacity 0.25s;

	&:disabled,
	&:hover {
		opacity: 0.5;
	}
`;

export const ModalStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		transform: 'translate(-50%, -50%)',
		fontSize: '16px',
		lineHeight: '24px',
		padding: '45px 50px',
		maxWidth: '410px',
		maxHeight: '420px',
	},
};

export const MobileStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		transform: 'translate(-50%, -50%)',
		fontSize: '16px',
		lineHeight: '24px',
		padding: '45px 50px',
		width: '90%',
	},
};

export const Flex = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;

	h2 {
		font-size: 22px;
		color: ${color.base} !important;
		${font.medium};
		margin-bottom: 12px;
	}

	p {
		width: 100%;
		font-size: 14px;
		margin-bottom: 28px;
	}

	label {
		font-size: 14px;
	}
`;

export const BtnWrap = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;

	button {
		padding: 8px 16px;
		color: ${color.white};
		background-color: ${color.danger};
		border-radius: 27px;

		&.outline {
			color: ${color.danger};
			background-color: transparent;
			border: 1px solid ${color.danger};
			margin-right: 16px;
		}
	}
`;
