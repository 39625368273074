import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Components
import { Button, Input, ModalWrapp } from '../../../../shared/components';
import Privacy from '../../../general/PrivacyTerms/Privacy';
import Terms from '../../../general/PrivacyTerms/Terms';

// Assets
import logo from '../../../../assets/myvote-logo.svg';

// Styles
import { MainWrap, InnerWrap, RegWrap, TextWrap, PText } from '../SignIn/style';
import { color } from '../../../../shared/utils/styles';

// TS Interfaces
import { ISignUpData, IError } from './';
interface ISignUp {
	mutations: { onSignUp: any };
	hooks: {
		errorState: IError;
		signUpData: ISignUpData;
		setSignUpData: any;
	};
}

const SignUp = (props: ISignUp) => {
	const { onSignUp } = props.mutations;
	const { errorState, signUpData, setSignUpData } = props.hooks;

	const { email, password, passwordConfirmation } = signUpData;

	// Modal
	const [modalIsOpen, setIsOpen] = useState(false);
	const [isPrivacyP, setPrivacyP] = useState(false);

	// Open modal with default Privacy/Terms content
	const openModal = (isPrivacyP: boolean) => {
		setIsOpen(true);
		setPrivacyP(isPrivacyP);
	};

	// Close modal
	const closeModal = () => {
		setIsOpen(false);
	};

	return (
		<MainWrap>
			<InnerWrap>
				<img src={logo} alt='app-logo' />
				<TextWrap>
					<h1>Let's get started</h1>
					<p>Create your account to start voting</p>
				</TextWrap>
				<form
					noValidate
					onSubmit={(e: React.ChangeEvent<HTMLFormElement>) => {
						e.preventDefault();
						onSignUp(email, password, passwordConfirmation);
					}}
				>
					<Input
						id='email'
						label='Email Address'
						name='email'
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setSignUpData({
								...signUpData,
								email: e.target.value,
							})
						}
						value={signUpData.email}
						autoFocus
						required
						borderColor={
							errorState.emailText && errorState.emailText !== ''
								? color.danger
								: ''
						}
						error={
							errorState.emailText && errorState.emailText !== '' ? true : false
						}
						errorText={errorState.emailText}
					/>

					<Input
						id='password'
						label='Password'
						name='password'
						type={signUpData.showPassword ? 'text' : 'password'}
						autoComplete='current-password'
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setSignUpData({
								...signUpData,
								password: e.target.value,
							})
						}
						value={signUpData.password}
						required
						borderColor={
							errorState.passwordText && errorState.passwordText !== ''
								? color.danger
								: ''
						}
						error={
							errorState.passwordText && errorState.passwordText !== ''
								? true
								: false
						}
						errorText={errorState.passwordText}
					/>

					<Input
						id='confirmPassword'
						label='Confirm Password'
						name='confirmPassword'
						type={signUpData.showPasswordC ? 'text' : 'password'}
						autoComplete='current-password'
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setSignUpData({
								...signUpData,
								passwordConfirmation: e.target.value,
							})
						}
						value={signUpData.passwordConfirmation}
						required
						borderColor={
							errorState.passwordTextC && errorState.passwordTextC !== ''
								? color.danger
								: ''
						}
						error={
							errorState.passwordTextC && errorState.passwordTextC !== ''
								? true
								: false
						}
						errorText={errorState.passwordTextC}
					/>

					<Button type='submit' text='Next' label={'Submit'} />

					<RegWrap>
						<Link to='/'>
							Already have an account? <span>Sign in</span>
						</Link>
					</RegWrap>
				</form>
			</InnerWrap>

			<PText>
				By signing up you agree with{' '}
				<span
					onClick={() => {
						openModal(false);
					}}
				>
					Terms of Use
				</span>{' '}
				and{' '}
				<span
					onClick={() => {
						openModal(true);
					}}
				>
					Privacy Policy
				</span>
			</PText>

			<ModalWrapp isOpen={modalIsOpen} onRequestClose={closeModal}>
				{isPrivacyP ? <Privacy /> : <Terms />}
			</ModalWrapp>
		</MainWrap>
	);
};

export default SignUp;
