import styled from 'styled-components';
import checkedIcon from '../../../assets/icons/checked.svg';
import { color } from '../../utils/styles';

export const InputWrap = styled.div`
	width: 100%;
	color: ${color.base};
	margin-top: 0;
	margin-bottom: 16px;
	border: 0;
	margin: 0;
	display: inline-flex;
	padding: 0;
	position: relative;
	min-width: 0;
	flex-direction: column;
	vertical-align: top;
	margin-bottom: 16px;
`;

export const ILabel = styled.label`
	/* top: -4px; */
	top: -3px;
	left: 0;
	position: absolute;
	transform: translate(0, 24px) scale(1);
	z-index: 1;
	transform: translate(14px, 20px) scale(1);
	pointer-events: none;
	transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
		transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
	display: block;
	transform-origin: top left;
	color: ${color.placeholder};

	&.shrink {
		transform: translate(14px, -6px) scale(0.75);
		color: ${color.primary};
		outline: none;
	}

	@media (max-width: 375px) {
		top: -5px;

		&.shrink {
			transform: translate(14px, -1px) scale(0.75);
		}
	}
`;

export const InnerWrap = styled.div`
	position: relative;
	background-color: ${color.white};
	border-radius: 4px;
	width: 100%;
	color: ${color.placeholder};
	cursor: text;
	display: inline-flex;
	position: relative;
	font-size: 1rem;
	box-sizing: border-box;
	align-items: center;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	font-weight: 400;
	line-height: 1.1876em;
	letter-spacing: 0.00938em;

	input {
		font: inherit;
		color: ${color.base};
		background-color: ${color.white};
		width: 100%;
		border: 0;
		height: 20px;
		margin: 0;
		display: block;
		padding: 6px 0 7px;
		min-width: 0;
		box-sizing: content-box;
		animation-name: mui-auto-fill-cancel;
		letter-spacing: inherit;
		animation-duration: 10ms;
		-webkit-tap-highlight-color: transparent;
		box-shadow: none;
		padding: 17px 14px 15px 14px;

		@media (max-width: 375px) {
			padding: 15px 10px;
		}
	}

	fieldset {
		border-color: ${color.secondary};
		top: -5px;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 0;
		padding: 0 8px;
		overflow: hidden;
		position: absolute;
		border-style: solid;
		border-width: 1px;
		border-radius: inherit;
		pointer-events: none;

		legend {
			width: auto;
			height: 11px;
			display: block;
			padding: 0;
			font-size: 0.75em;
			max-width: 0.01px;
			text-align: left;
			transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
			visibility: hidden;

			& > span {
				display: inline-block;
				padding-left: 5px;
				padding-right: 5px;
			}

			&.shrink {
				max-width: 1000px;
				transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
			}
		}
	}
`;

export const IconWrap = styled.div`
	display: flex;
	width: 45px;
	justify-content: flex-end;
	/* padding: 18.5px 14px; */

	button,
	span,
	svg {
		width: 100%;
		height: 100%;
	}

	span {
		display: flex;
		justify-content: center;
	}

	button {
		cursor: pointer;
		position: relative;
		border-radius: 100%;
		height: 45px;
		width: 45px;
		left: -5px;

		&:hover,
		&:focus {
			background-color: rgba(0, 0, 0, 0.04);
		}
	}

	svg {
		width: 24px;

		path {
			fill: ${color.placeholder};
		}
	}
`;

export const ErrorText = styled.div`
	color: ${color.danger};
	white-space: pre-line;
	margin-top: 3px;
	margin-left: 0;
	margin-right: 14px;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	font-weight: 400;
	line-height: 1.66;
	text-align: left;
	font-size: 0.75rem;
	margin-bottom: 8px;
	margin-top: 8px;
	display: flex;
	background-color: transparent;

	svg {
		margin-right: 8px;
	}

	p {
		font: inherit;
		flex: 1;
		margin: 0 !important;
	}
`;

export const CheckBox = styled.label`
	flex: 1;
	left: -9px;
	margin: 0;
	position: relative;
	cursor: pointer;
	display: flex;
	align-items: center;

	.c-icon {
		color: rgba(0, 0, 0, 0.54);
		padding: 9px;
		width: 16px;
		height: 16px;
		box-sizing: content-box;

		span {
			width: 100%;
			display: flex;
			align-items: inherit;
			justify-content: inherit;
			position: relative;
			width: 100%;
			height: 100%;

			input {
				top: 0;
				left: 0;
				width: 100%;
				cursor: inherit;
				height: 100%;
				margin: 0;
				opacity: 0;
				padding: 0;
				z-index: 1;
				position: absolute;
			}

			.c-check {
				width: 16px;
				height: 16px;
				box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2),
					inset 0 -1px 0 rgba(16, 22, 26, 0.1);
				border-radius: 3px;
				background-color: #f5f8fa;
				background-image: linear-gradient(
					180deg,
					hsla(0, 0%, 100%, 0.8),
					hsla(0, 0%, 100%, 0)
				);

				&.checked {
					background-color: #106ba3;
					background-image: linear-gradient(
						180deg,
						hsla(0, 0%, 100%, 0.1),
						hsla(0, 0%, 100%, 0)
					);

					&::before {
						width: 16px;
						height: 16px;
						content: '';
						display: block;
						background-image: url(${checkedIcon});
						background-repeat: no-repeat;
						background-size: 78%;
						background-position: 40% center;
					}
				}
			}
		}
	}
`;

export const TextArea = styled.div`
	width: 100%;
	margin-bottom: 30px;

	label {
		margin-bottom: 8px;
		display: block;
	}

	textarea {
		width: 100%;
		border: 1px solid ${color.secondary};
		border-radius: 4px;
		padding: 17px 14px 15px 14px;
		font-size: 14px;
		font: inherit;
		resize: vertical;
		min-height: 145px;
		max-height: 400px;
	}
`;

export const BasicInput = styled.div`
	width: 100%;
	margin-bottom: 30px;

	label {
		margin-bottom: 8px;
		display: block;
	}

	input {
		font: inherit;
		color: ${color.base};
		background-color: ${color.white};
		width: 100%;
		border: 0;
		height: 20px;
		margin: 0;
		display: block;
		padding: 6px 0 7px;
		min-width: 0;
		animation-name: mui-auto-fill-cancel;
		letter-spacing: inherit;
		animation-duration: 10ms;
		-webkit-tap-highlight-color: transparent;
		box-shadow: none;
		padding: 17px 14px 15px 14px;
		border: 1px solid ${color.secondary};
		border-radius: 4px;
		font-size: 14px;
		font: inherit;
	}
`;
