import styled from 'styled-components';
import { color } from '../../utils/styles';

export const PollWrap = styled.ul`
	position: relative;
	width: 100%;
	padding: 0;
`;

export const PollList = styled.li`
	list-style-type: none;
	display: flex;
	margin-bottom: 30px;
	width: 100%;
	box-sizing: border-box;
	user-select: none;
	color: ${color.base};
	position: relative;
	left: -7px;
	font-weight: 400;

	&:last-of-type {
		margin-bottom: 16px;
	}

	.poll-input {
		width: 100%;
		height: 40px;
		padding: 0 10px;
		margin-right: 15px;
		font-size: 14px;
	}

	.drag {
		cursor: row-resize;

		&:hover {
			opacity: 0.5;
		}
	}
`;
