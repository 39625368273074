export const color = {
	white: '#FFFFFF',
	black: '#000000',
	base: '#202124', // black
	primary: '#0040BF', // blue
	success: '#008000', // green
	danger: '#d93025', // red
	warning: '#E67E22', // orange
	secondary: '#DADCE0', // light grey
	background: '#FAFAFA', //bg light color
	placeholder: 'rgba(31, 32, 35, 0.6)',
	checked: '#0084FF',
	shadow: '0px 3px 6px #00000017',
	line: '#DFDFDF',
};

export const font = {
	regular:
		'font-family: "Roboto", "Helvetica", "Arial", sans-serif; font-weight: normal;',
	medium:
		'font-family: "Roboto", "Helvetica", "Arial", sans-serif; font-weight: 500;',
};
