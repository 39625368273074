import React, { useEffect, useState } from 'react';

// Styles
import { RCIcon, ChartWrap } from './styles';
import { color } from '../../utils/styles';

// TS Interfaces
interface IRadialChart {
	progress?: string;
	dimension: number;
	children: any;
}

export const RadialChart = React.memo((props: IRadialChart) => {
	const { progress, dimension, children } = props;
	const [stroke, setStroke] = useState<boolean | null>(null);

	useEffect(() => {
		setTimeout(() => {
			setStroke(true);
		});
	}, []);

	const radius = 80;
	const strokeWidth = 10;
	const circleRadius = Math.min(radius, 85);
	const circumference = 2 * 3.14 * circleRadius;
	const strokeLength = stroke ? (circumference / 100) * Number(progress) : 0;

	return (
		<ChartWrap>
			<RCIcon
				viewBox='0 0 180 180'
				xmlns='http://www.w3.org/2000/svg'
				width={dimension}
				height={dimension}
			>
				<circle
					className='radial-chart-total'
					stroke={color.primary}
					strokeWidth={strokeWidth}
					fill='none'
					cx='90'
					cy='90'
					r={circleRadius}
				/>
				<circle
					className={progress === '0' ? 'no-progress' : 'radial-chart-progress'}
					cx='90'
					cy='90'
					r='80'
					fill='none'
					stroke={color.primary}
					strokeWidth={strokeWidth}
					strokeDasharray={`${strokeLength},${circumference}`}
					strokeLinecap='square'
				/>
			</RCIcon>
			{children}
		</ChartWrap>
	);
});

export default RadialChart;
