import React, { useState } from 'react';

// Components
import { ModalWrapp } from '../../../../shared/components';
import Privacy from '../../../general/PrivacyTerms/Privacy';
import Terms from '../../../general/PrivacyTerms/Terms';
import Form from './Form';

// Assets
import logo from '../../../../assets/myvote-logo.svg';

// Styles
import { MainWrap, InnerWrap, PText } from './style';

// TS Interfaces
import { ISignInData, IError } from '@myvote/common';

interface ISignIn {
	mutations: { onSignIn: any };
	data: {
		signInData: ISignInData;
		errorState: IError;
		onSubmit: any;
	};
	hooks: {
		setSignInData: any;
	};
}

const SignIn = (props: ISignIn) => {
	const { onSignIn } = props.mutations;
	const { onSubmit, signInData, errorState } = props.data;
	const { setSignInData } = props.hooks;

	const { email, password, remember } = signInData;

	// Modal
	const [modalIsOpen, setIsOpen] = useState(false);
	const [isPrivacyP, setPrivacyP] = useState(false);

	// Open modal with default Privacy/Terms content
	const openModal = (isPrivacyP: boolean) => {
		setIsOpen(true);
		setPrivacyP(isPrivacyP);
	};

	// Close modal
	const closeModal = () => {
		setIsOpen(false);
	};

	return (
		<MainWrap>
			<InnerWrap>
				<img src={logo} alt='app-logo' />
				<Form
					data={{
						onSubmit,
						onSignIn,
						signInData,
						setSignInData,
						errorState,
						email,
						password,
						remember,
					}}
				/>
			</InnerWrap>

			<PText>
				By signing in you agree with{' '}
				<span
					onClick={() => {
						openModal(false);
					}}
				>
					Terms of Use
				</span>{' '}
				and{' '}
				<span
					onClick={() => {
						openModal(true);
					}}
				>
					Privacy Policy
				</span>
			</PText>

			<ModalWrapp isOpen={modalIsOpen} onRequestClose={closeModal}>
				{isPrivacyP ? <Privacy /> : <Terms />}
			</ModalWrapp>
		</MainWrap>
	);
};

export default SignIn;
