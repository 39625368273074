import React from 'react';

// Notifications
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Redux
import { Provider } from 'react-redux';
import { ReduxStore } from '@myvote/common';

// General Styles
import GeneralStyles from './shared/GeneralStyles';

// Apollo
import { ApolloProvider } from '@apollo/client';
import { Apollo } from '@myvote/common';

// Components
import Routes from './routes/Routes';

function App() {
	return (
		<ApolloProvider client={Apollo}>
			<Provider store={ReduxStore}>
				<GeneralStyles />
				<ToastContainer transition={Slide} autoClose={3000} />
				<Routes />
			</Provider>
		</ApolloProvider>
	);
}

export default App;
