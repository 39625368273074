import styled from 'styled-components';
import { color } from '../../../../shared/utils/styles';

export const Wrap = styled.main`
	top: 50%;
	left: 50%;
	color: black;
	border: 1px solid ${color.secondary};
	padding: 48px 64px;
	position: absolute;
	transform: translate(-50%, -50%);
	box-shadow: 0px 3px 6px #0000000f;
	background-color: white;
	max-width: 455px;
`;

export const InnerWrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	form {
		width: 100%;
	}
`;

export const TextWrap = styled.div`
	max-width: 327px;
	margin-bottom: 48px;
	text-align: center;

	h1 {
		font-size: 22px;
		line-height: 29px;
		font-weight: 500;
		margin-bottom: 16px;
	}
`;

export const Btn = styled.button`
	max-width: 327px;
	background-color: ${color.primary};
	color: ${color.white};
	border-radius: 27px;
	padding: 16px 135px;
`;
