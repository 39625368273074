import React from 'react';

// Notifications
// import { toast } from 'react-toastify';

// Material Ui
import { CircularProgress } from '@material-ui/core';

// Components
import CmsLayout from '../../../general/CmsLayout';
import { Icon } from '../../../../shared/components';
import Timer from '../PollList/Timer';

// Icons
import { ReactComponent as BackIcon } from '../../../../assets/icons/back_to_poll.svg';
import { ReactComponent as VoteSuccImg } from '../../../../assets/voted-illustration.svg';

// Styles
import {
	PollWrap,
	BackBtn,
	Wrapper,
	Time,
	Description,
	PoolForm,
	ChoiceWrap,
	VoteBtn,
	ResultsWrap,
	Result,
	SuccMsg,
} from './style';
import { color } from '../../../../shared/utils/styles';

// TS Interfaces
import { ISinglePoll } from '@myvote/common';

const SinglePoll = (props: ISinglePoll) => {
	const {
		user,
		poll,
		voted,
		expired,
		success,
		setSuccess,
		vote,
		history,
		value,
		handleChange,
		handleMultipleChange,
		votePending,
		setVotePending,
		statusText,
		toggleCheckBox,
	} = props.data;

	// Display success/error message after voting
	let message;
	if (success === true) {
		message = (
			<SuccMsg>
				<Icon icon={VoteSuccImg} svg className='vote-img' />
				<p>Thank you for voting</p>
				<p>Your vote is pending to be written on blockchain</p>
				<button onClick={() => history.push('/')}>OK</button>
			</SuccMsg>
		);
	} else if (success === false) {
		message = (
			<p style={{ color: 'red', marginTop: '100px' }}>
				Error with voting. Please try later
			</p>
		);
	} else if (success === 'empty-option') {
		message = (
			<p style={{ color: 'red', marginTop: '100px' }}>
				You must choose an option
			</p>
		);
	}
	// Display voting or result screen
	let voteOrResult;

	if (expired === true) {
		// Find maximum vote value in poll and convert it to %
		let maxVotes = Math.max.apply(
			Math,
			poll.options.map(function (option) {
				return option.numberOfVotes;
			})
		);
		const maxVotePercent = Number(
			((maxVotes / poll.totalVotes) * 100).toFixed(2)
		);
		let votePercent;

		voteOrResult = (
			<ResultsWrap>
				{poll.options.map((option, index) => {
					if (option.numberOfVotes > 0) {
						votePercent = (
							(option.numberOfVotes / poll.totalVotes) *
							100
						).toFixed(2);
						votePercent = Number(votePercent);
					} else {
						votePercent = 0;
					}

					return (
						<Result key={poll.id + index}>
							<div className='text'>
								<p
									style={
										votePercent === maxVotePercent
											? { fontWeight: 'bold', color: color.checked }
											: {}
									}
								>
									{option.name}
								</p>
								<p
									style={
										votePercent === maxVotePercent
											? { fontWeight: 'bold', color: color.checked }
											: {}
									}
								>
									{votePercent + '%'}
								</p>
							</div>
							<div className='progress'>
								<span style={{ width: `${votePercent + '%'}` }}></span>
							</div>
						</Result>
					);
				})}
			</ResultsWrap>
		);
	} else if (voted === false) {
		voteOrResult = (
			<React.Fragment>
				<PoolForm
					noValidate
					onSubmit={(e) => {
						e.preventDefault();
						if (value?.length === 0) {
							setSuccess('empty-option');
						} else {
							setVotePending(true);
							const uniqueOptions = [
								//@ts-ignore
								...new Set(
									value?.map((option) => {
										return { name: option.name };
									})
								),
							];
							vote({
								variables: {
									id: poll.id,
									options: uniqueOptions,
									userID: user.id,
								},
							})
								.then(() => {
									setSuccess(true);
									setVotePending(false);
									// setUser(data.me);
								})
								.catch((error: string) => {
									setSuccess(false);
									console.log(error);
								});
						}
					}}
				>
					{success !== true && (
						<React.Fragment>
							{poll.type === 'single' ? (
								<React.Fragment>
									{poll.options.map((option, index) => (
										<ChoiceWrap key={poll.id + index}>
											<input
												type='radio'
												value={option.name}
												checked={
													//@ts-ignore
													value.length > 0 && value[0].name === option.name
												}
												onChange={handleChange}
											/>
											<span>{option.name}</span>
											<span className='checkmark'></span>
										</ChoiceWrap>
									))}
								</React.Fragment>
							) : (
								<React.Fragment>
									{poll.options.map((option, index) => (
										<ChoiceWrap key={index} className='multiple'>
											<input
												type='checkbox'
												id={'choices' + option.name}
												name={'choices' + option.name}
												value={option.name}
												onChange={(e) =>
													handleMultipleChange(
														index,
														!toggleCheckBox[index].checked,
														e
													)
												}
											/>
											<span className='text'>{option.name}</span>
											<span className='checkmark'></span>
										</ChoiceWrap>
									))}
								</React.Fragment>
							)}
							{votePending ? (
								<CircularProgress style={{ marginTop: '100px' }} />
							) : (
								<VoteBtn type='submit'>Vote</VoteBtn>
							)}
						</React.Fragment>
					)}
				</PoolForm>
				{message}
			</React.Fragment>
		);
	} else if (voted === true) {
		voteOrResult = (
			<p
				style={{
					marginTop: 32,
					color: color.primary,
					fontWeight: 500,
				}}
			>
				RESULTS WILL BE AVAILABLE AFTER POLL EXPIRES
			</p>
		);
	} else {
		voteOrResult = (
			<div style={{ maxWidth: 800 }}>
				<p
					style={{
						marginTop: 32,
						color: color.warning,
						fontWeight: 500,
					}}
				>
					Loading ...
				</p>
			</div>
		);
	}

	return (
		<CmsLayout>
			<Wrapper>
				{success !== true && (
					<BackBtn type='button' onClick={() => history.goBack()}>
						<Icon icon={BackIcon} svg />
						BACK TO POLL LIST
					</BackBtn>
				)}
				<PollWrap>
					{success !== true && (
						<React.Fragment>
							<h1> {poll.title}</h1>
							<Time>
								<Timer
									className={statusText}
									text={
										statusText === 'finished' ? 'Finished ' : 'Expiration date'
									}
									data={poll}
								/>
							</Time>
							<Description>{poll.description}</Description>
						</React.Fragment>
					)}
					{voteOrResult}
				</PollWrap>
			</Wrapper>
		</CmsLayout>
	);
};

export default SinglePoll;
