import React, { Component } from 'react';
import {
	SortableContainer,
	SortableElement,
	SortableHandle,
} from 'react-sortable-hoc';

// Styles
import { PollList, PollWrap } from './style';

// Icons
import DragIcon from '../../../assets/icons/drag-icon.svg';
import DeleteIcon from '../../../assets/icons/delete-icon.svg';

const DragHandle = SortableHandle(() => (
	<img className='drag' src={DragIcon} alt='' />
));

const SortableItem = SortableElement(({ data }: any) => {
	const { value, index, handleInputChange, handleRemoveClick } = data;

	return (
		<PollList>
			<DragHandle />
			<input
				type='text'
				value={value.name}
				onChange={(e) => handleInputChange(e, index)}
				placeholder='Poll Option*'
				className='poll-input'
			/>
			{index > 1 && (
				<button
					style={{
						padding: 0,
					}}
					color='secondary'
					onClick={() => handleRemoveClick(index)}
				>
					<img src={DeleteIcon} alt='' />
				</button>
			)}
		</PollList>
	);
});

const SortableContainerMain = SortableContainer(({ children }: any) => {
	return <PollWrap>{children}</PollWrap>;
});

class SortableInput extends Component {
	render() {
		const { pollParams, handleInputChange, onSortEnd, handleRemoveClick }: any =
			//@ts-ignore
			this.props.data;

		return (
			<SortableContainerMain onSortEnd={onSortEnd} useDragHandle>
				{pollParams.options.map((value: any, index: any) => (
					<SortableItem
						key={index}
						data={{ value, index, handleInputChange, handleRemoveClick }}
						index={index}
					/>
				))}
			</SortableContainerMain>
		);
	}
}

export default SortableInput;
