import styled from 'styled-components';

export const SearchWrap = styled.div`
	position: relative;

	& > div {
		position: absolute;
		left: 8px;
		padding: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	input.s-input {
		padding: 0 16px 0 38px;
	}

	@media (max-width: 525px) {
		margin-bottom: 16px;
	}
`;
