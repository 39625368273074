import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// GraphQL
import { useMutation, useQuery } from '@apollo/client';
import { VOTE, ME, DATE } from '@myvote/common';

// Components
import SinglePoll from './SinglePoll';

// TS Interfaces
import { IUser } from '@myvote/common';

const Index = () => {
	let location = useLocation();
	let history = useHistory();

	// User data
	const { data: me } = useQuery(ME);
	const user = me && me.me;

	// @ts-ignore
	const { poll, statusText } = location.state;

	// Check if user has already voted
	const [voted, setVoted] = useState<boolean | null>(null);
	const [expired, setExpired] = useState<boolean | null>(null);
	const [success, setSuccess] = useState<boolean | null>(null);

	// Handler for radio buttons state (voting screen)
	const [value, setValue] = useState<Array<{ name: string }>>([]);
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue([{ name: event.target.value }]);
	};

	// Handler for chekcboxes state (voting screen) - multiple choices
	const [toggleCheckBox, setToggleCheckBox] = useState<
		Array<{
			name: string;
			checked: boolean;
		}>
	>([]);

	// Set checked state form multiple options on component mount
	if (toggleCheckBox.length === 0) {
		poll.options.map((option: any) => {
			setToggleCheckBox((prevState) => [
				...prevState,
				{ name: option.name, checked: false },
			]);
			return null;
		});
	}

	const handleMultipleChange = (
		index: number,
		checked: boolean,
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const newArr = [...toggleCheckBox];
		newArr[index].checked = checked;
		setToggleCheckBox(newArr);
		if (checked) {
			setValue([...value, { name: event.target.value }]);
		} else {
			const filtered = value.filter(function (el) {
				return el.name !== event.target.value;
			});
			setValue(filtered);
		}
	};

	// Voting
	const [vote] = useMutation(VOTE);
	var pollDate = new Date(poll.expiresAt);
	const [votePending, setVotePending] = useState(false);

	// Date fetch
	const { data: dateQuery, loading } = useQuery(DATE);

	useEffect(() => {
		// Check if poll is expired
		let date;
		if (!loading) {
			date = new Date(dateQuery.date);
			if (user) {
				if (date.getTime() > pollDate.getTime()) {
					setExpired(true);
				} else if (user.polls.length === 0) {
					setVoted(false);
				} else {
					// Check if user has voted
					user.polls.some((user: any) => {
						if (user.pollID === poll.id) {
							setVoted(true);
							return true;
						} else {
							setVoted(false);
						}

						return null;
					});
				}
			}
		}
	}, [user, poll.id, loading, dateQuery]);

	return (
		<SinglePoll
			data={{
				user,
				poll,
				voted,
				expired,
				success,
				setSuccess,
				vote,
				history,
				value,
				handleChange,
				handleMultipleChange,
				pollDate,
				votePending,
				setVotePending,
				statusText,
				toggleCheckBox,
				setToggleCheckBox,
			}}
		/>
	);
};

const mapStateToProps = (state: IUser) => ({
	user: state.user,
});

export default connect(mapStateToProps, null)(Index);
