import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

// GraphQl
import { useQuery, useMutation } from '@apollo/client';
import { POLL, UPDATE_POLL, CREATE_POLL } from '@myvote/common';

// Components
import CreateEditPoll from './CreateEditPoll';
import arrayMove from 'array-move';

// TS Interfaces
import { IDefault, ISortable } from '@myvote/common';

interface PollParams {
	title: string;
	description: string;
	type: string;
	expiresAt: Date;
	options: any;
}

interface Error {
	pollTitle?: string;
	optionError?: string;
	optionTitle?: string;
}

const Index = (props: IDefault) => {
	// Check if entered from poll list or nav
	let fromList;
	if (props.location.state !== null) {
		fromList = props.location.state.fromList;
	}

	//TODO: Duplicate options error handling
	const history = useHistory();

	// Get poll data
	//@ts-ignore
	let { pollID } = useParams();

	const { loading, data: poll } = useQuery(POLL, {
		variables: { id: pollID },
	});

	// Check if route is Edit or Create Poll
	let match = useRouteMatch('/admin/create-poll/');

	// Poll parameters
	const [pollParams, setPollParams] = useState<PollParams>({
		title: '',
		description: '',
		type: '',
		expiresAt: new Date(),
		options: [{ name: '' }, { name: '' }],
	});

	useEffect(() => {
		// refetch();
		// Edit poll or Create New Poll page detection - initial state
		if (!loading && !match) {
			//Edit Page
			setPollParams(poll.poll);
		} else {
			// Create Poll page
			setPollParams({
				title: '',
				description: '',
				type: 'single',
				expiresAt: new Date(),
				options: [{ name: '' }, { name: '' }],
			});
		}
	}, [loading]);

	// Error handling state
	const [errorState, setError] = useState<Error>({
		pollTitle: '',
		optionError: '',
		optionTitle: '',
	});

	// Date-Time change
	const handleDateChange = (date: Date) => {
		setPollParams({
			...pollParams,
			expiresAt: date,
		});
	};

	// Set Poll options and create
	const handleInputChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		index: number
	) => {
		const { value } = e.target;

		setPollParams({
			...pollParams,
			options: pollParams.options.map((item: { name: string }, i: number) => {
				return i === index ? { ...item, name: value } : item;
			}),
		});
	};

	// Move options position handler
	const onSortEnd = ({ oldIndex, newIndex }: ISortable) => {
		setPollParams({
			...pollParams,
			options: arrayMove(pollParams.options, oldIndex, newIndex),
		});
	};

	// Single / Multiple poll type change
	const handleChoice = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setPollParams({
			...pollParams,
			type: e.target.value,
		});
	};

	// Poll options

	// Add option
	const handleAddClick = () => {
		setPollParams({
			...pollParams,
			options: [...pollParams.options, { name: '' }],
		});
	};

	// Remove option
	const handleRemoveClick = (i: number) => {
		const newParams = pollParams.options.filter(function (
			option: { name: string },
			index: number
		) {
			return index !== i;
		});
		setPollParams({
			...pollParams,
			options: newParams,
		});
	};

	// Error Check / Handling when Creating/Updating Poll
	var submitErrors = false;

	const handleErrorsCheck = () => {
		//TODO: Transfer error handling to server

		// Check if poll have a title
		if (!pollParams.title) {
			setError((errorState: any) => ({
				...errorState,
				pollTitle: 'Poll must have a title',
			}));
			submitErrors = true;
		} else {
			setError((errorState: any) => ({
				...errorState,
				pollTitle: '',
			}));
		}

		// Check if poll have more than 1 option
		if (pollParams.options.length < 2) {
			setError((errorState: any) => ({
				...errorState,
				optionError: 'Poll must have at least 2 options',
			}));
			submitErrors = true;
		} else {
			setError((errorState: any) => ({
				...errorState,
				optionError: '',
			}));
		}

		// Check if poll option have title
		for (var i = 0; i < pollParams.options.length; i++) {
			if (pollParams.options[i].name === '') {
				setError((errorState: any) => ({
					...errorState,
					optionTitle: 'All option must have a title',
				}));
				submitErrors = true;
				break;
			} else {
				setError((errorState: any) => ({
					...errorState,
					optionTitle: '',
				}));
			}
		}
	};

	// Create Poll
	const [createPoll] = useMutation(CREATE_POLL);
	const handleCreatePoll = () => {
		let { description, expiresAt, title, options, type } = pollParams;
		handleErrorsCheck();

		if (!submitErrors) {
			// Update draft to published
			if (!match) {
				updatePoll({
					variables: {
						id: pollID,
						title,
						description,
						type,
						expiresAt,
						options,
						status: 'pending',
					},
				})
					.then(() => {
						history.push('/polls-list');
					})
					.catch((error: any) => {
						error.graphQLErrors.map(({ message }: any) => {
							setError((errorState: any) => ({
								...errorState,
								optionTitle: message,
							}));
							return null;
						});
					});
			} else {
				createPoll({
					variables: {
						title,
						description,
						type,
						expiresAt,
						options,
						status: 'pending',
					},
				})
					.then(() => {
						history.push('/polls-list');
					})
					.catch((error: any) => {
						error.graphQLErrors.map(({ message }: any) => {
							setError((errorState: any) => ({
								...errorState,
								optionTitle: message,
							}));
							return null;
						});
					});
			}
		}
	};

	// Update Poll
	const [updatePoll] = useMutation(UPDATE_POLL);
	const handleUpdatePoll = () => {
		let { description, expiresAt, title, options, type } = pollParams;
		handleErrorsCheck();

		if (!submitErrors) {
			if (!match) {
				updatePoll({
					variables: {
						id: pollID,
						title,
						description,
						type,
						expiresAt,
						options,
						status: 'draft',
					},
				})
					.then(() => {
						history.push('/polls-list');
					})
					.catch((error: any) => {
						console.log(error);
					});
			} else {
				createPoll({
					variables: {
						title,
						description,
						type,
						expiresAt,
						options,
						status: 'draft',
					},
				})
					.then(() => {
						history.push('/polls-list');
					})
					.catch((error: any) => {
						console.log(error);
					});
			}
		}
	};

	return (
		<CreateEditPoll
			data={{
				pollParams,
				errorState,
			}}
			hooks={{ setPollParams }}
			helpers={{
				handleDateChange,
				handleRemoveClick,
				handleAddClick,
				handleInputChange,
				handleCreatePoll,
				handleUpdatePoll,
				handleChoice,
				onSortEnd,
			}}
			routes={{ fromList, history }}
		/>
	);
};

export default Index;
