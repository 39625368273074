import React from 'react';

const Terms = () => {
	return (
		<React.Fragment>
			<h1>Terms of use</h1>
			<h2>Information Collection, Use and Sharing</h2>
			<p>
				We are the sole owners of the information collected on this site. We
				only have access to collect information that you voluntarily give us via
				email or other direct contact from you. We will not sell or rent this
				information to anyone.{' '}
			</p>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
				tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
				veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
				commodo consequat.
			</p>
			<h2>Your Access and Control Over Information</h2>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc turpis
				lacus, consectetur eu elit ac, elementum vestibulum neque. Nulla
				facilisi. Mauris placerat sit amet tortor at fermentum. Aliquam
				fringilla fermentum nunc, quis elementum libero tempus maximus. Donec
				vel nulla purus. Nullam non nibh nulla. Vestibulum pulvinar magna eget
				urna porta suscipit. Nunc suscipit facilisis diam ullamcorper mattis.
				Nunc maximus vestibulum tellus, et convallis enim tincidunt quis.
				Quisque posuere nunc at ante condimentum, vitae cursus ligula tristique.
				Cras ultricies, urna eu tempus elementum, nibh magna suscipit lectus, in
				sodales neque felis ut quam. Proin vehicula sit amet lectus vitae
				convallis. Fusce neque dolor, rhoncus id risus at, dapibus bibendum
				urna. Sed id luctus est, id tristique nulla. Interdum et malesuada fames
				ac ante ipsum primis in faucibus. Phasellus facilisis imperdiet odio,
				non mattis magna dictum at. Suspendisse iaculis sem augue, vel eleifend
				odio hendrerit a. Quisque convallis mollis rhoncus. Fusce nec nulla ut
				lectus laoreet posuere vitae a neque. Duis pharetra pulvinar augue, id
				mattis quam viverra ut. Nam tincidunt laoreet consequat.
			</p>
		</React.Fragment>
	);
};

export default Terms;
