import styled from 'styled-components';

export const ChartWrap = styled.div`
	position: relative;
	width: 90px;
	justify-content: center;
	display: flex;
`;

export const RCIcon = styled.svg`
	position: relative;
	display: inline-block;
	transition: all 0.3s ease-in;

	.radial-chart.no-progress .radial-chart-progress {
		opacity: 0;
	}

	.radial-chart-total {
		opacity: 0.3;
	}

	.radial-chart-progress {
		transform: rotate(90deg);
		transform-origin: center;
		transition: all 0.6s cubic-bezier(0.58, 0.16, 0.5, 1.14);
		transition-delay: 0.3s;
	}

	.no-progress {
		opacity: 0;
	}
`;
