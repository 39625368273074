import React from 'react';

// Components
import CmsLayout from '../../../general/CmsLayout';
import {
	Icon,
	RadialChart,
	Search,
	Filter,
	Loader,
} from '../../../../shared/components';
import Timer from './Timer';

// Icons
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-icon.svg';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/arrow-icon.svg';

// Styles
import { color } from '../../../../shared/utils/styles';
import {
	Wrapper,
	PollWrap,
	PollList,
	PollLinkWrap,
	PollLink,
	TitleWrap,
	TopBar,
	VNumbers,
	Overlay,
} from './style';

// TS Interfaces
import { IPoll, IPollsList } from '@myvote/common';

const PollsList = (props: IPollsList) => {
	const { user, filter, usersList, date } = props.data;
	const { search } = props.hooks;
	const {
		editSearchTerm,
		dynamicSearch,
		handleDelete,
		handleViewOrEdit,
	} = props.helpers;
	let status;

	return (
		<CmsLayout>
			<Wrapper>
				<PollWrap>
					<TopBar>
						<Filter
							filter={search.filter}
							admin={user.accountType !== 'voter' ? true : false}
							onClick={(e: { target: HTMLButtonElement }) =>
								filter(e.target.value)
							}
						/>
						<Search value={search.searchTerm} onChange={editSearchTerm} />
					</TopBar>
					{(() => {
						const dinamicSearch = dynamicSearch(search, user, 'web');
						if (dinamicSearch !== undefined) {
							if (dinamicSearch.length !== 0) {
								return (
									//@ts-ignore
									<PollList>
										{dinamicSearch
											.slice()
											.reverse()
											.map((poll: IPoll) => {
												// Expired and pending status check
												const pollDate = new Date(poll.expiresAt).getTime();
												const nowDate = date.getTime();
												let statusText: string;

												if ((status = poll.status === 'draft')) {
													statusText = 'draft';
													status = (
														<p style={{ color: color.warning, opacity: 0.7 }}>
															Draft
														</p>
													);
												} else if (nowDate > pollDate) {
													statusText = 'finished';
													status = (
														<p style={{ color: color.primary }}>
															Finished{' '}
															<span className='s-result'> - See results</span>
														</p>
													);
												} else if ((status = poll.status === 'published')) {
													statusText = 'published';
													status = (
														<p style={{ color: color.success, opacity: 0.7 }}>
															Active
														</p>
													);
												} else {
													statusText = 'pending';
													status = (
														<p style={{ color: color.danger, opacity: 0.7 }}>
															Pending
														</p>
													);
												}

												return (
													<PollLinkWrap
														key={poll.id}
														className={
															poll.status === 'pending' ? 'disabled-link' : ''
														}
													>
														<PollLink
															onClick={() => handleViewOrEdit(poll, statusText)}
														>
															<TitleWrap>
																<h2>{poll.title}</h2>
																<div className='b-wrap'>
																	{status}
																	<Timer data={poll} />
																</div>
															</TitleWrap>
														</PollLink>
														{(() => {
															if (
																user.accountType === 'superadmin' ||
																user.accountType === 'admin'
															) {
																if (usersList && poll.status === 'published') {
																	// Calculate progress and send it to Chart Component
																	const users = usersList.users.length - 1;
																	let usersVoted;
																	if (poll.usersVoted == null) {
																		usersVoted = 0;
																	} else {
																		usersVoted = poll.usersVoted;
																	}
																	const progress = (
																		(usersVoted / users) *
																		100
																	).toFixed(0);
																	return (
																		<React.Fragment>
																			<RadialChart
																				progress={progress}
																				dimension={64}
																			>
																				{usersVoted && usersList ? (
																					<VNumbers>
																						{usersVoted}/{users}
																					</VNumbers>
																				) : (
																					<VNumbers>0/{users} </VNumbers>
																				)}
																			</RadialChart>
																		</React.Fragment>
																	);
																} else if (
																	poll.status === 'draft' ||
																	poll.status === 'pending'
																) {
																	return (
																		<Icon
																			icon={DeleteIcon}
																			svg
																			className='danger'
																			onClick={(
																				e: React.MouseEvent<HTMLButtonElement>
																			) => {
																				e.preventDefault();
																				handleDelete(poll.id);
																			}}
																		/>
																	);
																}
															} else {
																return (
																	<button
																		style={{ width: 'auto' }}
																		onClick={() =>
																			handleViewOrEdit(poll, statusText)
																		}
																	>
																		<Icon
																			className={'open-icon'}
																			icon={ArrowIcon}
																			svg
																		/>
																	</button>
																);
															}
														})()}
													</PollLinkWrap>
												);
											})}
									</PollList>
								);
							} else {
								return (
									<p className='not-found'>No results have been found :(</p>
								);
							}
						} else {
							return <Loader />;
						}
					})()}
					<Overlay />
				</PollWrap>
			</Wrapper>
		</CmsLayout>
	);
};

export default PollsList;
