import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Auth
import PrivateRoute from './PrivateRoutes';

// Components
import SignIn from '../containers/pages/public/SignIn';
import SignUp from '../containers/pages/public/SignUp';
import Recovery from '../containers/pages/public/Recovery';
import ResetPassword from '../containers/pages/public/Recovery/ResetPassword';
import Welcome from '../containers/pages/public/Welcome';
import PollsList from '../containers/pages/public/PollList';
import SinglePoll from '../containers/pages/public/SinglePoll';
import Dashboard from '../containers/pages/admin/Dashboard';
import Common from '../containers/pages/admin/CreateEditPoll/Common';
import Users from '../containers/pages/admin/Users';
import Profile from '../containers/pages/public/Profile';

const Routes = () => {
	return (
		<Router>
			<Switch>
				<Route exact path='/' component={SignIn} />
				<Route exact path='/register' component={SignUp} />
				<PrivateRoute exact path='/welcome' component={Welcome} />

				{/* Reset Password */}
				<Route exact path='/account-recovery' component={Recovery} />
				<Route exact path='/password/reset' component={ResetPassword} />

				{/* Polls */}
				<PrivateRoute exact path='/polls-list' component={PollsList} />
				<PrivateRoute path='/poll/:pollID' component={SinglePoll} />
				<PrivateRoute
					path='/admin/edit-poll/:pollID'
					component={Common}
					admin
				/>
				<PrivateRoute
					exact
					path='/admin/create-poll'
					component={Common}
					admin
				/>

				{/* Menu */}
				<PrivateRoute
					exact
					path='/admin/dashboard'
					component={Dashboard}
					admin
				/>
				<PrivateRoute exact path='/admin/users' component={Users} admin />
				<PrivateRoute exact path='/profile' component={Profile} />
			</Switch>
		</Router>
	);
};

export default Routes;
