import React from 'react';
import { Link } from 'react-router-dom';

// COmponents
import { Button, Input } from '../../../../shared/components';
import { BWrap, RegWrap } from './style';
import { color } from '../../../../shared/utils/styles';

const Form = (props: any) => {
	const {
		onSubmit,
		onSignIn,
		signInData,
		setSignInData,
		errorState,
		email,
		password,
		remember,
	} = props.data;

	return (
		<form
			data-testid='form'
			id='signInForm'
			noValidate
			onSubmit={(e) => {
				e.preventDefault();
				onSubmit(onSignIn, email, password, remember);
			}}
		>
			<Input
				id='email'
				label='Email Address'
				name='email'
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					setSignInData({
						...signInData,
						email: e.target.value,
					})
				}
				value={signInData.email}
				autoFocus
				required
				borderColor={
					errorState.emailText && errorState.emailText !== ''
						? color.danger
						: ''
				}
				error={
					errorState.emailText && errorState.emailText !== '' ? true : false
				}
				errorText={errorState.emailText}
			/>

			<Input
				id='password'
				label='User Password'
				name='password'
				type={signInData.showPassword ? 'text' : 'password'}
				autoComplete='current-password'
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					setSignInData({
						...signInData,
						password: e.target.value,
					})
				}
				value={signInData.password}
				required
				borderColor={
					errorState.passwordText && errorState.passwordText !== ''
						? color.danger
						: ''
				}
				error={
					errorState.passwordText && errorState.passwordText !== ''
						? true
						: false
				}
				errorText={errorState.passwordText}
			/>

			<BWrap>
				<Input
					type='checkbox'
					value='remember'
					onChange={() =>
						setSignInData({
							...signInData,
							remember: !signInData.remember,
						})
					}
					label='Remember me'
				/>
				<Link to='/account-recovery'>Forgot password?</Link>
			</BWrap>
			<Button type='submit' text={'Sign In'} label={'Submit'} />
			<RegWrap>
				<Link to='/register'>
					{"Don't have an account? "}
					<span>Sign Up</span>
				</Link>
			</RegWrap>
		</form>
	);
};

export default Form;
