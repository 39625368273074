import React from 'react';

// Styles
import { ButtonWrap } from './styles';

// TS Interfaces
interface IButton {
	label?: string;
	type: any;
	text: string;
	onClick?: any;
	style?: any;
}

const Button = (props: IButton) => {
	const { text, type, label, onClick, style } = props;
	return (
		<ButtonWrap
			type={type}
			aria-label={label}
			onClick={onClick}
			style={style && style}
		>
			{text}
		</ButtonWrap>
	);
};

export default Button;
