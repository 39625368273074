import React from 'react';

import { NavLink } from 'react-router-dom';

// Syles
import { IconWrap, Btn } from './style';

// TS Interfaces
interface IIcon {
	text?: string;
	className?: string;
	width?: string | number;
	height?: string | number;
	icon?: any;
	to?: string;
	svg?: boolean;
	onClick?: any;
}
const Icon = (props: IIcon) => {
	const { text, className, width, height, icon, to, svg, onClick } = props;
	const Icon = icon;

	return (
		<React.Fragment>
			{!onClick ? (
				<React.Fragment>
					{to ? (
						<IconWrap
							as={NavLink}
							to={to}
							activeClassName='is-active'
							className={className}
						>
							{svg ? (
								<Icon style={{ width: width, height: height }} />
							) : (
								<img
									src={icon}
									alt='icon'
									style={{ width: width, height: height }}
								/>
							)}
							{text && <div>{text}</div>}
						</IconWrap>
					) : (
						<IconWrap className={className}>
							{svg ? (
								<Icon style={{ width: width, height: height }} />
							) : (
								<img
									src={icon}
									alt='icon'
									style={{ width: width, height: height }}
								/>
							)}
							{text && <div>{text}</div>}
						</IconWrap>
					)}
				</React.Fragment>
			) : (
				<Btn type='button' onClick={onClick} className={className}>
					{svg ? (
						<Icon style={{ width: width, height: height }} />
					) : (
						<img
							src={icon}
							alt='icon'
							style={{ width: width, height: height }}
						/>
					)}
					{text && <div>{text}</div>}
				</Btn>
			)}
		</React.Fragment>
	);
};

export default Icon;
