import styled from 'styled-components';
import { font } from '../../../../shared/utils/styles';

export const Wrapper = styled.div`
	width: 100%;
	max-width: 675px;
	margin-right: auto;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;

export const Heading = styled.h2`
	${font.medium};
	align-self: flex-start;
	margin-bottom: 32px;

	@media (max-width: 950px) {
		display: none;
	}
`;
