import React, { useState, useEffect } from 'react';

// Notifications
import { toast } from 'react-toastify';

// Grapqhl
import { useQuery, useMutation } from '@apollo/client';
import { USERS, UPDATE_PERMISSION } from '@myvote/common';

// Components
import Users from './Users';

// TS Interfaces
export interface IUser {
	accountType?: string;
	email?: string;
	id?: string;
}

const Index = () => {
	// Save Button state
	const [save, setSave] = useState(true);

	// Users {} for select change
	const [editUser, setUser] = useState<Array<IUser>>([
		{
			accountType: '',
			email: '',
			id: '',
		},
	]);

	// Users {} for update
	const [updateUser, setUpdateU] = useState<Array<IUser>>([
		{
			accountType: '',
			email: '',
			id: '',
		},
	]);

	// Queries / Mutations
	const { data, loading } = useQuery(USERS);
	const [updatePermission] = useMutation(UPDATE_PERMISSION);

	useEffect(() => {
		if (!loading) {
			setUser(data.users);
		}
	}, [data, setUser, loading]);

	// Select change
	const handleChange = (
		event: React.ChangeEvent<HTMLSelectElement>,
		email: string,
		id: string
	) => {
		const index = editUser.findIndex((x: IUser) => x.id === id);

		const newUsers = [...editUser];
		newUsers[index] = {
			...newUsers[index],
			id: id,
			email: email,
			accountType: event.target.value,
		};

		setUser(newUsers);
		if (updateUser[0].id === null) {
			setUpdateU([{ id: id, email: email, accountType: event.target.value }]);
		} else {
			setUpdateU([
				...updateUser,
				{ id: id, email: email, accountType: event.target.value },
			]);
		}

		setSave(false);
	};

	// Update user
	const updateUserDB = () => {
		updateUser.forEach((user) => {
			updatePermission({
				variables: {
					id: user.id,
					accountType: user.accountType,
				},
			})
				.then((response) => {
					setSave(true);
					if (response.data.updatePermissions === true) {
						toast.success('Permission change successfully', {
							toastId: 'u-success',
						});
					} else {
						toast.error('Permission change error!', {
							toastId: 'u-error',
						});
					}
				})
				.catch((e) => {
					console.log(e);
				});
		});
	};

	return (
		<Users data={{ editUser, save }} helpers={{ handleChange, updateUserDB }} />
	);
};

export default Index;
