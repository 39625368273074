import React from 'react';
import { useLocation, NavLink } from 'react-router-dom';

// Styles
import { TabletMenu, HeaderBar, Toolbar, Logo } from './style';

// Icons
import logo from '../../../assets/myvote-logo.svg';
import menuIcon from '../../../assets/icons/nav/menu-icon.svg';

const Header = ({ setOpenMenu }: any) => {
	let location = useLocation();

	return (
		<HeaderBar>
			<Toolbar>
				<Logo as={NavLink} to='/'>
					<img src={logo} alt='' />
				</Logo>
			</Toolbar>

			{!location.pathname.includes('/poll/') && (
				<TabletMenu>
					<button type='button' onClick={() => setOpenMenu(true)}>
						<img src={menuIcon} alt='' />
					</button>
					{location.pathname === '/polls-list' && <h1>Poll List</h1>}
					{location.pathname === '/admin/dashboard' && <h1>Dashboard</h1>}
					{location.pathname === '/admin/users' && <h1>Users</h1>}
					{location.pathname === '/admin/create-poll' && <h1>Create Poll</h1>}
					{location.pathname === '/profile' && <h1>Account Settings</h1>}
					<div style={{ width: 44 }} />
				</TabletMenu>
			)}
		</HeaderBar>
	);
};

export default Header;
