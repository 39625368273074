import React from 'react';

// Components
import Icon from '../Icon';

// Styles
import { SearchWrap } from './style';

// Icons
import { ReactComponent as SearchIcon } from '../../../assets/icons/search-icon.svg';

// TS Interfaces
import { ISearchChange } from '@myvote/common';

const Search = (props: ISearchChange) => {
	const { value, onChange } = props;

	return (
		<SearchWrap>
			<Icon icon={SearchIcon} svg />
			<input
				className='s-input'
				type='text'
				value={value}
				onChange={onChange}
				placeholder='Search by poll name'
			/>
		</SearchWrap>
	);
};
export default Search;
