import React from 'react';

// Styles
import {
	ErrorText,
	Heading,
	Choice,
	POptionsWrap,
	AddBtn,
	PollBtn,
	PollWrap,
	BackBtn,
} from './style';
import { color } from '../../../../shared/utils/styles';

// Components
import CmsLayout from '../../../general/CmsLayout';
import { Input, Icon, SortableInput } from '../../../../shared/components';

// Material UI
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
	KeyboardTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
// Icons
import AddIcon from '../../../../assets/icons/playlist_add-24px.svg';
import { ReactComponent as BackIcon } from '../../../../assets/icons/back_to_poll.svg';

// TS Interfaces
import { IHistory } from '@myvote/common';

interface ICreateEditPoll {
	data: {
		errorState: any;
		pollParams: any;
	};
	hooks: { setPollParams: any };
	helpers: {
		handleCreatePoll: any;
		handleUpdatePoll: any;
		handleDateChange: any;
		handleRemoveClick: any;
		handleAddClick: any;
		handleInputChange: any;
		handleChoice: any;
		onSortEnd: any;
	};
	routes: {
		fromList: string | undefined;
		history: IHistory;
	};
}

const CreateEditPoll = (props: ICreateEditPoll) => {
	const { pollParams, errorState } = props.data;
	const { setPollParams } = props.hooks;
	const {
		handleCreatePoll,
		handleUpdatePoll,
		handleDateChange,
		handleRemoveClick,
		handleAddClick,
		handleInputChange,
		handleChoice,
		onSortEnd,
	} = props.helpers;
	const { fromList, history } = props.routes;

	return (
		<CmsLayout>
			{pollParams && (
				<PollWrap>
					{fromList && (
						<BackBtn type='button' onClick={() => history.goBack()}>
							<Icon icon={BackIcon} svg />
							BACK TO POLL LIST
						</BackBtn>
					)}
					<Heading>Create Poll</Heading>
					<form
						noValidate
						onSubmit={(e) => {
							e.preventDefault();
						}}
						style={{ width: '100%' }}
					>
						<Input
							id='name'
							label='Poll name'
							name='name'
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setPollParams({
									...pollParams,
									title: e.target.value,
								})
							}
							value={pollParams.title}
							autoFocus
							error={errorState.pollTitle !== '' ? true : false}
							errorText={errorState.pollTitle}
							placeholder='Enter poll name'
							basic
						/>
						<Input
							type='textarea'
							label='Poll description'
							value={pollParams.description}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setPollParams({
									...pollParams,
									description: e.target.value,
								})
							}
							placeholder='Enter poll description'
							rows={5}
							cols={50}
						/>

						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDatePicker
								margin='normal'
								id='expire'
								label='Expiration date'
								format='MM/dd/yyyy'
								value={pollParams.expiresAt}
								onChange={handleDateChange}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								className={'picker'}
							/>
							<KeyboardTimePicker
								margin='normal'
								id='time-picker'
								label='Expiration time'
								value={pollParams.expiresAt}
								onChange={handleDateChange}
								KeyboardButtonProps={{
									'aria-label': 'change time',
								}}
								className={'picker'}
							/>
						</MuiPickersUtilsProvider>

						<Choice>
							<label htmlFor='poll-type'>Poll type: </label>
							<select
								name='poll-type'
								id='poll-type'
								onChange={(e) => handleChoice(e)}
							>
								<option value='single'>Single choice</option>
								<option value='multiple'>Multiple choice</option>
							</select>
						</Choice>

						<POptionsWrap>
							<p>Poll options</p>
							<SortableInput
								//@ts-ignore
								data={{
									pollParams,
									handleInputChange,
									onSortEnd,
									handleRemoveClick,
								}}
							/>

							<AddBtn onClick={() => handleAddClick()}>
								<img src={AddIcon} alt='' />
								Add option
							</AddBtn>
							<div style={{ margin: '30px 0' }}>
								<ErrorText>{errorState.optionTitle}</ErrorText>
								<ErrorText>{errorState.optionError}</ErrorText>
							</div>
						</POptionsWrap>

						<PollBtn onClick={() => handleCreatePoll()}>Publish Poll</PollBtn>
						<PollBtn
							onClick={() => handleUpdatePoll()}
							color='secondary'
							style={{
								backgroundColor: color.white,
								color: color.primary,
								border: `1px solid ${color.primary}`,
							}}
						>
							Save as Draft
						</PollBtn>
					</form>
				</PollWrap>
			)}
		</CmsLayout>
	);
};

export default CreateEditPoll;
