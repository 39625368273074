import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// GraphQl
import { useMutation } from '@apollo/client';
import { SIGN_IN } from '@myvote/common';

// Components
import SignIn from './SignIn';

// State
import { useSignIn, useError } from '@myvote/common';

// TS Interfaces
import { IError } from '@myvote/common';

// main submit function
const handleSubmit = (
	onSignIn: any,
	email: any,
	password: any,
	remember: any
) => {
	onSignIn(email, password, remember);
};

function Index({ onSubmit = handleSubmit }) {
	const history = useHistory();

	// Redirect if authorized
	useEffect(() => {
		if (document.cookie.match(/^(.*;)?\s*sid\s*=\s*[^;]+(.*)?$/) !== null) {
			history.push('/polls-list');
		}
	}, [history]);

	// Error handling state
	const [errorState, setError] = useError();

	// Mutatation / State for SignIn
	const [signIn] = useMutation(SIGN_IN);
	const [signInData, setSignInData] = useSignIn();

	const onSignIn = (email: string, password: string, remember: boolean) => {
		signIn({
			variables: {
				email,
				password,
				remember,
			},
		}).then(
			() => {
				history.push('/polls-list');
			},
			(error: any) => {
				error.graphQLErrors.map(({ message }: any) => {
					try {
						const { error } = JSON.parse(message);

						setError((errorState: IError) => ({
							...errorState,
							emailText: '',
							passwordText: '',
						}));

						error.map((val: any) => {
							if (val.context.key === 'email') {
								setError((errorState: IError) => ({
									...errorState,
									emailText: val.message,
								}));
							}
							if (val.context.key === 'password') {
								setError((errorState: IError) => ({
									...errorState,
									passwordText: val.message,
								}));
							}
							return null;
						});
					} catch (e) {
						setError({
							passwordText: message,
						});
					}

					return null;
				});
			}
		);
	};

	return (
		<SignIn
			mutations={{ onSignIn }}
			data={{ signInData, errorState, onSubmit }}
			hooks={{ setSignInData }}
		/>
	);
}

export default Index;
