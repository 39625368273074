import styled from 'styled-components';
import { color, font } from '../../../../shared/utils/styles';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;

	@media (max-width: 950px) {
		margin-top: -64px;
	}
`;

export const BackBtn = styled.button`
	display: flex;
	align-items: center;
	${font.medium}
	font-size: 14px;
	margin-bottom: 32px;
	padding: 0;

	svg {
		width: 16px;
		height: 16px;
		margin-right: 8px;
	}

	@media (max-width: 525px) {
		margin-bottom: 16px;
	}
`;

export const PollWrap = styled.div`
	position: relative;
	width: 100%;
	height: calc(100vh - 42px - 64px - 64px);
	overflow-y: auto;

	h1 {
		${font.medium}
		font-size: 26px;
		margin-bottom: 16px;
		max-width: 800px;
		overflow-wrap: break-word;
		word-break: break-all;
	}

	@media (max-width: 1375px) {
		h1 {
			max-width: 600px;
		}
	}

	@media (max-width: 525px) {
		height: calc(100vh - 42px - 16px - 24px);
		padding-left: 0;
		padding-right: 24px;
		margin-right: -24px;
		width: calc(100% + 24px);

		h1 {
			max-width: calc(100% - 48px);
		}
	}
`;

export const Time = styled.div`
	margin-bottom: 32px;
	max-width: 800px;
	display: flex;

	@media (max-width: 1375px) {
		max-width: 600px;
	}
`;

export const Description = styled.p`
	margin-bottom: 32px;
	max-width: 800px;
	overflow-wrap: break-word;
	word-break: break-all;

	@media (max-width: 1375px) {
		max-width: 600px;
	}

	@media (max-width: 525px) {
		max-width: calc(100% - 48px);
	}
`;

export const PoolForm = styled.form`
	margin-bottom: 32px;

	input {
		box-shadow: 0px 3px 6px #0000000f;
	}
`;

export const ChoiceWrap = styled.label`
	display: flex;
	align-items: center;
	position: relative;
	padding:10px 21px;
	margin-bottom: 16px;
	cursor: pointer;
	font-size: 22px;
	user-select: none;
	box-shadow: ${color.shadow};
	max-width: 385px;
	min-height: 55px;
	border-radius: 4px;
	overflow-wrap: break-word;
	word-break: break-all;

	&:last-of-type {
		margin-bottom: 32px;
	}

	span {
		position: relative;
		z-index: 2;
		overflow-wrap: break-word;
		word-break: break-all;
	}

	input {
		appearance: auto;
		position: absolute;
		opacity: 0;
		cursor: pointer;
		left: 0;
		overflow-wrap: break-word;
		word-break: break-all;
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 5px;
		height: 100%;
		width: 100%;
		background-color: ${color.white};
		transition: background-color 0.25s;
		z-index: 1;
		border-radius: 4px;
	}

	/* &:hover input ~ .checkmark {
		background-color: ${color.secondary};
	} */

	& input:checked ~ .checkmark {
		background-color: ${color.checked};
	}

	& input:checked ~ span {
		color: ${color.white};
	}

	&.multiple {
		box-shadow: none;
		align-items: center;

		.text {
			margin-left: 20px;
		}

		& input:checked ~ span {
			color: ${color.base};
		}

		.checkmark {
			width: 20px;
			height: 20px;
			box-shadow: ${color.shadow};
			top: 50%;
			transform: translateY(-50%);

			&::after {
				content: '';
				position: absolute;
				display: block;
				left: 6px;
				top: 3px;
				width: 7px;
				height: 12px;
				border: solid white;
				border-width: 0 3px 3px 0;
				transform: rotate(45deg);
			}
		}
	}

	@media(max-width:525px){
		max-width: calc(100% - 48px);
	}
`;

export const VoteBtn = styled.button`
	padding: 7px 53px;
	background-color: ${color.primary};
	color: ${color.white};
	border-radius: 27px;
`;

export const ResultsWrap = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 800px;

	@media (max-width: 1375px) {
		max-width: 600px;
	}
`;

export const Result = styled.div`
	.text {
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;

		p {
			font-size: 14px;

			&:last-of-type {
				${font.medium}
			}
		}
	}

	.progress {
		position: relative;
		height: 10px;
		background-color: ${color.secondary};
		border-radius: 4px;
		overflow: hidden;
		box-shadow: 0px 3px 6px #0000000f;
		margin-bottom: 32px;

		span {
			position: absolute;
			left: 0;
			height: 100%;
			background-color: ${color.checked};
		}
	}
`;

export const SuccMsg = styled.div`
	width: 205px;
	top: 150px;
	left: 50%;
	transform: translateX(-50%);
	position: absolute;
	text-align: center;

	.vote-img {
		justify-content: center;

		svg {
			width: 83px;
			height: 110px;
		}
	}

	p {
		&:first-of-type {
			color: ${color.primary};
			font-size: 22px;
			margin-bottom: 48px;
		}

		&:last-of-type {
			color: ${color.base};
			${font.medium};
			margin-bottom: 32px;
		}
	}

	button {
		background-color: ${color.primary};
		color: ${color.white};
		padding: 8px 33px;
		border-radius: 18px;
	}
`;
