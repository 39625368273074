import React from 'react';

// Components
import CmsLayout from '../../../general/CmsLayout';

// Styles
import { Heading, Wrapper } from './style';

const Dashboard = () => {
	return (
		<CmsLayout>
			<Wrapper>
				<Heading>Dashboard</Heading>
			</Wrapper>
		</CmsLayout>
	);
};

export default Dashboard;
