import React from 'react';

// Modal
import Modal from 'react-modal';

// Style
import { ModalStyles, Image, Btn } from './style';

// Assets
import closeImg from '../../../assets/icons/close-icon.png';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const ModalWrapp = ({ isOpen, onRequestClose, children }: any) => {
	return (
		<Modal
			isOpen={isOpen}
			// onAfterOpen={afterOpenModal}
			onRequestClose={onRequestClose}
			style={ModalStyles}
			contentLabel='Modal'
		>
			<Btn onClick={onRequestClose}>
				<Image src={closeImg} alt='' />
			</Btn>

			{children}
		</Modal>
	);
};

export default ModalWrapp;
