import { createGlobalStyle } from 'styled-components';
import { color, font } from './utils/styles';

export default createGlobalStyle`
	html, body, #root {
		height: 100%;
		min-height: 100%;
		margin: 0;
		overflow: hidden;

		&.ReactModal__Body--open {
			.ReactModalPortal > * {
				opacity: 1
			}
		}
	}

	body {
		color:  ${color.base};
		-webkit-tap-highlight-color: transparent;
		font-size: 100%;
		${font.regular};
		background-color: ${color.background};
	}

	.MuiOutlinedInput-notchedOutline {
		border-color: ${color.secondary};
	}

	*, *:after, *:before, input[type="search"] {
		box-sizing: border-box;
	}

	a,button {
		text-decoration: none;
		color: inherit;
		transition: opacity .25s;
		font-size: inherit;
		cursor: pointer;

		&:hover {
			opacity: .7;
		}
	}

	ul {
		list-style: none;
	}

	ul, li, ol, dd, h1, h2, h3, h4, h5, h6, p {
		padding: 0;
		margin: 0;
	}

	button {
		background: none;
		border: none;
	}

	/* Workaround for IE11 focus highlighting for select elements */
	select::-ms-value {
		background: none;
		color: #42413d;
	}

	[role="button"], button, input, select, textarea {
		outline: none;
		&:focus {
			outline: none;
		}
		&:disabled {
			opacity: 1;
		}
	}

	[role="button"], button, input, textarea {
		appearance: none;
	}

	select:-moz-focusring {
		color: transparent;
		text-shadow: 0 0 0 #000;
	}

	select::-ms-expand {
		display: none;
	}

	.is-active {
		color: ${color.primary};

		span {
			font-weight: bold;
		}

		svg path {
			fill: ${color.primary};
		}
	}

	.container {
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
		flex: 1;
	}

	.row {
		display: flex;
		justify-content: center;

		@media (max-width: 768px) {
			padding: 0 30px;
			box-sizing: border-box;
		}

		@media (max-width: 525px) {
			padding: 0 20px;
			flex-wrap: wrap;
			max-height: 370px;
			margin-bottom: 30px;
		}
	}

	p {
		margin: 0;
	}

	.ReactModal__Overlay {
		cursor:pointer
	}

	.ReactModalPortal {

		.ReactModal__Overlay {
			z-index: 9999;
			background-color: rgba(0, 0, 0, 0.4) !important;
		}

		.ReactModal__Content {
			box-shadow: 0px 3px 6px #0000000F;
			/* border: 1px solid #DADCE0 !important; */
			border: 0 !important;
			border-radius: 8px !important;
			cursor: initial;

			h1 {
				margin-bottom: 32px;
			}

			h2 {
				margin-bottom: 16px;
				color: ${color.primary};
				font-size: 20px;
				line-height: 26px;
			}

			p {
				margin-bottom: 32px;

			}

			@media (max-width: 525px) {
				padding:24px !important;

				h1 {
					font-size:22px;
				}
			}
		}

		& > * {
			opacity: 0;
		}
	}

	.overlay {
		position: fixed;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0, .6);
		z-index: 999;
		cursor: pointer;
		transition: opacity 200ms ease-in-out;

		&.ReactModal__Overlay--after-open {
				opacity: 1;
		}

		&.ReactModal__Overlay--before-close {
				opacity: 0;
		}
	}

	.full {
		display: flex;
		flex-direction: column;
	}

	body, select {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	html {
		touch-action: manipulation;
	}

	.blue {
		color: ${color.primary};
	}

	.green {
		color: ${color.success};
	}

	.warning {
		color: ${color.warning}
	}

	.red {
		color: ${color.danger};
	}

	h1,h2,h3 {
		font-weight: normal;
	}

	input,textarea {
		max-width: 675px;
		border-radius: 4px;
	}

	div.picker {
		margin-right: 32px;
		border: 1px solid ${color.secondary};
		background-color:${color.white};
		border-radius: 4px;

		label {
			transform: scale(1);
			color: ${color.base} !important;
			top: -26px;
		}

		input {
			height: auto;
			padding: 8px 16px;
			width: 100px;
			font-size: 14px;
			line-height: 19px;
		}

		& > div {
			margin: 0;
		}

		& > div::before,
		& > div::after{
			display: none;
		}

		&:last-of-type {
			margin-right: 0;
		}
	}

	select {
		cursor: pointer;
	}

`;
