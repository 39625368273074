import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Notifications
import { toast } from 'react-toastify';

// Grapqhl
import { useQuery, useMutation } from '@apollo/client';
import {
	POLLS,
	DELETE_POLL,
	USERS,
	useSearch,
	dynamicSearch,
	DATE,
} from '@myvote/common';

// Redux
import { connect } from 'react-redux';

// Components
import PollsList from './PollsList';

// Interfaces
import { IPoll, IUser } from '@myvote/common';

const Index = ({ user }: IUser) => {
	let history = useHistory();

	// Queries
	const { data: pollsData, loading, refetch } = useQuery(POLLS);
	const { data: usersList } = useQuery(USERS);

	// Muations
	const [deletePoll] = useMutation(DELETE_POLL);

	// Date fetch
	const { data: dateQuery, loading: dateLoading } = useQuery(DATE);
	let date = new Date();
	if (!dateLoading) {
		date = new Date(dateQuery.date);
	}

	/*******************
	# Search  && Filter
	********************/
	const [search, setSearch] = useSearch();

	// Search term handler
	const editSearchTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearch({ ...search, searchTerm: e.target.value });
	};

	useEffect(() => {
		if (!loading) {
			setSearch((search) => ({ ...search, pollsData }));
		}
	}, [setSearch, pollsData, loading]);

	// Set Filter Value in search state
	const filter = (value: string) => {
		setSearch({ ...search, filter: value });
	};

	// Delete Poll
	const handleDelete = (id: string) => {
		deletePoll({
			variables: {
				id,
			},
		})
			.then(() => {
				toast.success('Success', {
					toastId: 'u-success-p',
				});
				refetch();
			})
			.catch((error) => {
				toast.error('Error deliting poll', {
					toastId: 'u-error-p',
				});
				console.log(error);
			});
	};

	// Go to edit  poll screen
	const handleViewOrEdit = (poll: IPoll, statusText: string) => {
		if (poll.status === 'published') {
			history.push({
				pathname: '/poll/' + poll.id,
				state: { poll, statusText },
			});
		} else {
			history.push({
				pathname: '/admin/edit-poll/' + poll.id,
				state: { fromList: true },
			});
		}
	};

	return (
		<PollsList
			data={{ user, filter, usersList, date }}
			hooks={{
				search,
			}}
			helpers={{
				dynamicSearch,
				editSearchTerm,
				handleDelete,
				handleViewOrEdit,
			}}
		/>
	);
};

const mapStateToProps = (state: IUser) => ({
	user: state.user,
});

export default connect(mapStateToProps, null)(Index);
