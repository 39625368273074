import React from 'react';
import { color } from '../../utils/styles';

// Styles
import { FilterWrap } from './style';
const activeStyle = {
	color: color.primary,
	boxShadow: '0px 2px 4px #0000000A',
};
const inactiveStyle = {
	color: color.base,
	boxShadow: '0px 4px 8px #00000012',
};

// TS Interfaces
interface IFilter {
	filter: string;
	admin: boolean;
	onClick: any;
}

const Filter = (props: IFilter) => {
	const { filter, onClick, admin } = props;

	return (
		<FilterWrap>
			<button
				value={'all'}
				style={filter === 'all' ? activeStyle : inactiveStyle}
				onClick={onClick}
			>
				All
			</button>
			{admin && (
				<button
					value={'draft'}
					style={
						filter === 'draft'
							? //@ts-ignore
							  { activeStyle, color: color.warning }
							: inactiveStyle
					}
					onClick={onClick}
				>
					Drafts
				</button>
			)}
			<button
				value={'active'}
				style={
					filter === 'active'
						? //@ts-ignore
						  { activeStyle, color: color.success }
						: inactiveStyle
				}
				onClick={onClick}
			>
				Active
			</button>
			<button
				value={'finished'}
				style={filter === 'finished' ? activeStyle : inactiveStyle}
				onClick={onClick}
			>
				Finished
			</button>
		</FilterWrap>
	);
};
export default Filter;
