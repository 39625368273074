import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Utils
import { isJson } from '@myvote/common';

// GraphQl
import { useMutation } from '@apollo/client';
import { SIGN_UP } from '@myvote/common';

// Components
import SignUp from './SignUp';

// TS Interfaces
export interface ISignUpData {
	email: string;
	password: string;
	showPassword: boolean;
	passwordConfirmation: string;
	showPasswordC: boolean;
}

export interface IError {
	emailText?: string;
	passwordText?: string;
	passwordTextC?: string;
}

const Index = () => {
	const history = useHistory();

	// Error handling state
	const [errorState, setError] = useState<IError>({
		emailText: '',
		passwordText: '',
		passwordTextC: '',
	});

	// Redirect if authorized
	useEffect(() => {
		if (document.cookie.match(/^(.*;)?\s*sid\s*=\s*[^;]+(.*)?$/) !== null) {
			history.push('/polls-list');
		}
	}, [history]);

	// Mutatation / State for SignUp
	const [signUp] = useMutation(SIGN_UP);
	const [signUpData, setSignUpData] = useState<ISignUpData>({
		email: '',
		password: '',
		showPassword: false,
		passwordConfirmation: '',
		showPasswordC: false,
	});

	const onSignUp = (
		email: string,
		password: string,
		passwordConfirmation: string
	) => {
		signUp({
			variables: {
				email: email,
				password: password,
				passwordConfirmation: passwordConfirmation,
			},
		}).then(
			() => {
				history.push('/welcome');
			},
			(error: any) => {
				error.graphQLErrors.map(({ message }: any) => {
					if (isJson(message)) {
						const { error } = JSON.parse(message);
						try {
							setError({
								emailText: '',
								passwordText: '',
								passwordTextC: '',
							});

							error.map((val: any) => {
								let key = val.context.key;
								if (key === 'email') {
									setError((errorState: IError) => ({
										...errorState,
										emailText: val.message,
									}));
								}

								if (key === 'password') {
									setError((errorState: IError) => ({
										...errorState,
										passwordText: val.message,
									}));
								}

								if (key === 'passwordConfirmation') {
									setError((errorState: IError) => ({
										...errorState,
										passwordTextC: 'Passwords do not match',
									}));
								}
								return null;
							});
						} catch (e) {
							console.log(e);
						}
					} else {
						setError((errorState: IError) => ({
							...errorState,
							emailText: message,
							passwordText: '',
							passwordTextC: '',
						}));
					}
					return null;
				});
			}
		);
	};

	return (
		<SignUp
			mutations={{ onSignUp }}
			hooks={{
				signUpData,
				setSignUpData,
				errorState,
			}}
		/>
	);
};
export default Index;
