import React from 'react';

// Components
import CmsLayout from '../../../general/CmsLayout';
import { Loader } from '../../../../shared/components';

// Styles
import { Wrapper, Heading, Table, TopBar, Info, SaveBtn } from './style';
import { color } from '../../../../shared/utils/styles';

// TS Interfaces
import { IUser } from './';

interface IUsers {
	data: {
		editUser: Array<IUser>;
		save: boolean;
	};
	helpers: {
		handleChange: any;
		updateUserDB: any;
	};
}

const Users = (props: IUsers) => {
	const { editUser, save } = props.data;
	const { handleChange, updateUserDB } = props.helpers;

	return (
		<CmsLayout>
			{editUser[0].id ? (
				<Wrapper>
					<Heading>Users</Heading>
					<Table aria-label='simple table'>
						<TopBar>
							<div>Email</div>
							<div>Account Type</div>
						</TopBar>
						<div>
							{editUser.map((user: IUser) => {
								return (
									<Info key={user.id}>
										<div>{user.email}</div>
										{(() => {
											if (
												user.accountType === 'voter' ||
												user.accountType === 'admin'
											) {
												return (
													<select
														className={
															user.accountType === 'admin' ? 'admin' : ''
														}
														id='user-select'
														value={user.accountType}
														onChange={(e) =>
															handleChange(e, user.email, user.id)
														}
													>
														<option value={'voter'}>Voter</option>
														<option value={'admin'}>Admin</option>
														<option value={'deactivated'}>Deactivated</option>
													</select>
												);
											} else if (user.accountType === 'deactivated') {
												return (
													<select
														style={{ color: `${color.secondary}` }}
														id='user-select'
														value={user.accountType}
														onChange={(e) =>
															handleChange(e, user.email, user.id)
														}
													>
														<option value={'deactivated'}>Deactivated</option>
														<option value={'voter'}>Voter</option>
														<option value={'admin'}>Admin</option>
													</select>
												);
											} else {
												return (
													<div
														style={{
															textTransform: 'uppercase',
															color: `${color.danger}`,
															fontSize: '12px',
														}}
													>
														{user.accountType}
													</div>
												);
											}
										})()}
									</Info>
								);
							})}
						</div>
					</Table>
					<SaveBtn
						disabled={save}
						onClick={() => {
							updateUserDB();
						}}
					>
						Save
					</SaveBtn>
				</Wrapper>
			) : (
				<Loader />
			)}
		</CmsLayout>
	);
};

export default Users;
