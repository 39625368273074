import styled from 'styled-components';
import { color } from '../../utils/styles';

export const ButtonWrap = styled.button`
	width: 100%;
	padding: 16px 40px;
	margin-bottom: 16px;
	background-color: ${color.primary};
	color: ${color.white};
	border-radius: 27px;
	border: 0;
	text-transform: capitalize;
	font-family: inherit;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: 0;
	box-shadow: none;
	cursor: pointer;
	transition: opacity 0.25s;

	&:hover {
		opacity: 0.5;
	}

	&:focus {
		outline: none;
		opacity: 0.5;
	}

	@media (max-width: 375px) {
		padding: 14px 40px;
	}
`;
