import React, { useState } from 'react';

// Components
import Nav from './Nav';
import Header from './Header';

// Style
import { CmsWrapper, CmsMain, Overlay } from './style';

// TS interfaces
interface ICmsLayout {
	children: any;
	path?: string;
}

const CmsLayout = ({ children }: ICmsLayout) => {
	const [openMenu, setOpenMenu] = useState(false);

	return (
		<CmsWrapper>
			<Header hook={setOpenMenu} />
			<Nav open={openMenu} hook={setOpenMenu} />
			<Overlay
				className={openMenu ? 'm-active' : ''}
				onClick={() => setOpenMenu(false)}
			/>
			<CmsMain>{children}</CmsMain>
		</CmsWrapper>
	);
};

export default CmsLayout;
