import React, { useState, useRef } from 'react';

// Syles
import {
	InputWrap,
	ILabel,
	InnerWrap,
	IconWrap,
	ErrorText,
	CheckBox,
	TextArea,
	BasicInput,
} from './styles';
import { color } from '../../utils/styles';

// TS Interfaces
interface IInput {
	id?: string;
	name?: string;
	value?: string;
	type?: string;
	placeholder?: string;
	label?: string;
	required?: boolean;
	onChange?: any;
	className?: string;
	error?: boolean;
	errorText?: string;
	autoFocus?: boolean;
	rows?: number;
	cols?: number;
	basic?: boolean;
	checked?: boolean;
	autoComplete?: string;
	borderColor?: string;
}

const Input = (props: IInput) => {
	const {
		id,
		name,
		value,
		type,
		placeholder,
		label,
		required,
		onChange,
		className,
		error,
		errorText,
		autoFocus,
		rows,
		cols,
		basic,
		checked,
		borderColor,
	} = props;
	const [shrink, setShrink] = useState<string>('');
	const [reveal, setReveal] = useState<boolean>(false);
	const [check, setCheck] = useState<boolean>(false);
	const inputRef = useRef<any>(null);
	const inputCheckRef = useRef<any>(null);

	const handleInputFocus = () => {
		setShrink('shrink');
	};

	const handleInputBlur = () => {
		if (value === '') {
			setShrink('');
		}
	};

	const handleReveal = () => {
		setReveal(!reveal);
		inputRef.current.focus();
	};

	const handleClickCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(e);
		setCheck(!check);
	};

	let textColor = color.placeholder;
	if (error) {
		textColor = color.danger;
	}

	return (
		<React.Fragment>
			{(() => {
				if (type !== 'checkbox' && type !== 'textarea') {
					if (!basic) {
						return (
							<InputWrap className={className}>
								<ILabel
									className={shrink}
									id={`${id}-label`}
									style={{ color: textColor }}
									htmlFor={id}
								>
									{label}
									{required && <span aria-hidden='true'>&thinsp;*</span>}
								</ILabel>
								<InnerWrap>
									<input
										aria-invalid='false'
										id={id}
										autoFocus={autoFocus}
										name={name}
										autoComplete={name}
										onChange={onChange}
										onFocus={handleInputFocus}
										onBlur={handleInputBlur}
										type={reveal === false ? type : 'text'}
										value={value}
										ref={inputRef}
									></input>
									{type === 'password' && (
										<IconWrap className='root'>
											<button
												type='button'
												aria-label='toggle visibility'
												className='root'
												onClick={handleReveal}
											>
												<span className='label'>
													<svg
														focusable='false'
														viewBox='0 0 24 24'
														aria-hidden='true'
													>
														{reveal ? (
															<path d='M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z'></path>
														) : (
															<path d='M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z'></path>
														)}
													</svg>
												</span>
												<span className='sm'></span>
											</button>
										</IconWrap>
									)}
									<fieldset
										aria-hidden='true'
										style={{ borderColor: borderColor }}
									>
										<legend className={shrink}>
											<span>{label}&nbsp;*</span>
										</legend>
									</fieldset>
								</InnerWrap>
								{errorText && (
									<ErrorText>
										<svg
											aria-hidden='true'
											fill='currentColor'
											focusable='false'
											width='16px'
											height='16px'
											viewBox='0 0 24 24'
											xmlns='https://www.w3.org/2000/svg'
										>
											<path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z'></path>
										</svg>
										<p>{errorText}</p>
									</ErrorText>
								)}
							</InputWrap>
						);
					} else {
						return (
							<BasicInput className={className}>
								<label htmlFor='input'>{label}</label>
								<input
									aria-invalid='false'
									id={id}
									autoFocus={autoFocus}
									name={name}
									autoComplete={name}
									onChange={onChange}
									onFocus={handleInputFocus}
									onBlur={handleInputBlur}
									placeholder={placeholder}
									type={reveal === false ? type : 'text'}
									value={value}
									ref={inputRef}
								></input>
								{errorText && (
									<ErrorText>
										<svg
											aria-hidden='true'
											fill='currentColor'
											focusable='false'
											width='16px'
											height='16px'
											viewBox='0 0 24 24'
											xmlns='https://www.w3.org/2000/svg'
										>
											<path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z'></path>
										</svg>
										<p>{errorText}</p>
									</ErrorText>
								)}
							</BasicInput>
						);
					}
				} else if (type === 'checkbox') {
					return (
						<CheckBox className={className}>
							<span aria-disabled='false' className='c-icon'>
								<span>
									<input
										type='checkbox'
										onChange={(e) => handleClickCheckbox(e)}
										checked={checked}
										ref={inputCheckRef}
									/>
									<span
										className={!check ? 'c-check' : 'c-check checked'}
									></span>
								</span>
								<span></span>
							</span>
							<span>{label}</span>
						</CheckBox>
					);
				} else {
					return (
						<TextArea className={className}>
							<label htmlFor='text-area'>{label}</label>
							<textarea
								id={id}
								name={name}
								rows={rows}
								cols={cols}
								placeholder={placeholder}
								onChange={onChange}
								value={value}
							/>
						</TextArea>
					);
				}
			})()}
		</React.Fragment>
	);
};

export default Input;
