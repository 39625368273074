import styled from 'styled-components';
import { color } from '../../../shared/utils/styles';

export const TabletMenu = styled.div`
	display: none;
	padding: 24px;

	button {
		padding: 10px;
		position: relative;
		left: -10px;
	}
`;

export const Toolbar = styled.div`
	padding-left: 24px;
	padding-right: 24px;
	min-height: 64px;
	display: flex;
	position: relative;
	align-items: center;
`;

export const HeaderBar = styled.header`
	top: 0;
	left: auto;
	right: 0;
	position: absolute;
	display: flex;
	box-sizing: border-box;
	flex-shrink: 0;
	flex-direction: column;
	z-index: 2000;
	width: 100%;
	background-color: ${color.white};
	box-shadow: 0px 2px 4px #00000014;
	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

	@media (max-width: 950px) {
		background-color: transparent;
		box-shadow: none;

		${Toolbar} {
			display: none;
		}

		${TabletMenu} {
			display: flex;
			justify-content: space-between;
			align-items: center;

			h1 {
				font-size: 18px;
				line-height: 24px;
				font-weight: bold;
			}
		}
	}
`;

export const Logo = styled.div`
	cursor: pointer;
	flex-grow: 1;
	display: inline-flex;

	&:hover {
		text-decoration: none;
		opacity: 0.7;
	}
`;

export const IconLink = styled.div`
	padding: 12px;
	display: flex;
	align-items: center;

	&:hover svg path {
		fill: grey;
	}

	&.h-active svg path {
		fill: grey;
	}
`;

export const IconButton = styled.button`
	flex: 0 0 auto;
	padding: 12px;
	overflow: visible;
	font-size: 1.5rem;
	text-align: center;
	transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	color: inherit;
	border: 0;
	cursor: pointer;
	margin: 0;
	display: inline-flex;
	outline: 0;
	padding: 0;
	position: relative;
	align-items: center;
	user-select: none;
	border-radius: 0;
	vertical-align: middle;
	-moz-appearance: none;
	justify-content: center;
	text-decoration: none;
	background-color: transparent;
	-webkit-appearance: none;
	-webkit-tap-highlight-color: transparent;
`;
