import styled from 'styled-components';
import { color, font } from '../../../../shared/utils/styles';
import Dropdown from '../../../../assets/icons/poll_dropdown.svg';

export const PollWrap = styled.div`
	position: relative;
	width: 100%;
	height: calc(100vh - 32px - 64px);
	padding-left: 20px;
	overflow-y: auto;
	padding-bottom: 50px;

	.picker {
		.MuiFormHelperText-root.Mui-error {
			padding: 0 10px;
		}
	}

	@media (max-width: 525px) {
		padding-left: 0;
		padding-right: 24px;
		margin-right: -24px;
		width: calc(100% + 24px);

		.picker {
			margin-top: 30px;
			margin-bottom: 30px;
		}
	}
`;

export const BackBtn = styled.button`
	display: flex;
	align-items: center;
	${font.medium}
	font-size: 12px;
	margin-bottom: 18px;
	padding: 0;

	svg {
		width: 14px;
		height: 14px;
		margin-right: 8px;
	}
`;

export const Heading = styled.h2`
	${font.medium};
	align-self: flex-start;
	margin-bottom: 32px;

	@media (max-width: 950px) {
		display: none;
	}
`;

export const Choice = styled.div`
	text-transform: capitalize;
	letter-spacing: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 30px 0;

	label {
		margin-bottom: 8px;
	}

	select {
		font: inherit;
		color: ${color.base};
		background-color: ${color.white};
		border: 1px solid ${color.secondary};
		margin: 0;
		display: block;
		animation-name: mui-auto-fill-cancel;
		letter-spacing: inherit;
		animation-duration: 10ms;
		-webkit-tap-highlight-color: transparent;
		box-shadow: none;
		padding: 8px 16px;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none; /* Remove default arrow */
		background-image: url(${Dropdown}); /* Add custom arrow */
		background-repeat: no-repeat;
		background-position: 95% 50%;
		min-width: 155px;
	}

	input:hover,
	label:hover {
		cursor: pointer;
	}

	@media (max-width: 525px) {
		margin-top: 0;
	}
`;

export const POptionsWrap = styled.div`
	position: relative;
	left: -20px;
	margin-bottom: 30px;

	p {
		margin-bottom: 8px;
		margin-left: 20px;
	}

	input {
		font: inherit;
		color: ${color.base};
		background-color: ${color.white};
		border: 1px solid ${color.secondary};
		margin: 0;
		display: block;
		animation-name: mui-auto-fill-cancel;
		letter-spacing: inherit;
		animation-duration: 10ms;
		-webkit-tap-highlight-color: transparent;
		box-shadow: none;
		padding: 8px 16px;
	}

	@media (max-width: 525px) {
		li {
			left: auto;
			right: -14px;
		}
	}
`;

export const AddBtn = styled.button`
	padding: 5px 10px;
	display: flex;
	align-items: center;
	box-shadow: 0px 3px 6px #0000000f;
	border-radius: 20px;
	font-size: 13px;
	margin-left: 20px;
	background-color: ${color.white};

	img {
		margin-right: 5px;
	}
`;

export const ErrorText = styled.p`
	color: ${color.danger};
	margin-bottom: 10px;
	letter-spacing: 0;
`;

export const PollBtn = styled.button`
	background-color: ${color.primary};
	padding: 12px 32px;
	color: ${color.white};
	border-radius: 27px;
	margin-right: 16px;

	@media (max-width: 525px) {
		display: inline-flex;
		padding: 10px 22px;
		font-size: 14px;

		&:last-of-type {
			margin: 0;
		}
	}

	@media (max-width: 330px) {
	}
`;
