import styled from 'styled-components';
import { color } from '../../../../shared/utils/styles';

export const MainWrap = styled.main`
	top: 50%;
	left: 50%;
	color: black;
	border: 1px solid ${color.secondary};
	padding: 48px 40px;
	position: absolute;
	transform: translate(-50%, -50%);
	box-shadow: 0px 3px 6px #0000000f;
	background-color: white;
	max-width: 448px;
	width: 100%;

	@media (max-width: 525px) {
		height: 100%;
		padding: 24px;
	}
`;

export const InnerWrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	@media (max-width: 525px) {
		align-items: flex-start;
	}

	.back {
		padding: 10px 24px;
		background: ${color.primary};
		color: ${color.white};
		border-radius: 18px;
		align-self: flex-end;

		&:disabled {
			opacity: 0.4;
		}

		&:hover {
			opacity: 0.7;
		}
	}
`;

export const Text = styled.div`
	margin-bottom: 24px;
	width: 100%;

	h1 {
		text-align: center;
		font-size: 24px;
		margin-bottom: 48px;
	}

	@media (max-width: 525px) {
		margin-top: 24px;

		h1 {
			text-align: left;
			margin-bottom: 32px;
		}
	}
`;

export const BtnWrap = styled.div`
	margin-top: 16px;
	display: flex;
	width: 100%;
	justify-content: space-between;

	button {
		padding: 10px 24px;
		background: ${color.primary};
		color: ${color.white};
		border-radius: 18px;

		&:disabled {
			opacity: 0.4;

			&:hover {
				opacity: 0.4;
			}
		}

		&:hover {
			opacity: 0.7;
		}

		&.back {
			font-size: 14px;
			opacity: 1;
			padding: 6px 0;
			background: transparent;
			color: ${color.base};

			&:hover {
				opacity: 0.4;
			}
		}
	}
`;
