import styled from 'styled-components';
import { color } from '../../shared/utils/styles';

export const Wrapper = styled.div`
	margin-bottom: 32px;
	display: flex;
	flex-direction: column;
	padding: 0 26px;
	width: 256px;
	height: 100vh;
	background-color: ${color.white};

	.email {
		margin-bottom: 48px;
		margin-top: 32px;
		font-size: 12px;
		font-weight: bold;
	}

	.atitle {
		margin-bottom: 13px;
		font-size: 10px;
		font-weight: bold;
	}

	a,
	span,
	svg,
	button {
		@media (max-width: 1440px) {
			font-size: 14px;
		}
	}

	hr {
		/* margin-left: 26px; */
		margin-bottom: 13px;
		margin-top: 13px;
		width: 100%;
		border: none;
		height: 1px;
		background-color: ${color.line};

		@media (max-width: 525px) {
			margin-bottom: 7px;
			margin-top: 7px;
			height: 0;
			border: 1px solid ${color.line};
		}
	}

	a {
		width: 100%;
		display: flex;
		padding: 13px 0;
		align-items: center;

		&.is-active {
			span,
			svg path {
				fill: ${color.primary};
				font-weight: normal;

				&.a {
					opacity: 0;
				}
			}
		}
	}

	/* @media (max-width: 1600px) {
		width: 30%;
	}

	@media (max-width: 1024px) {
		width: 450px;
	} */

	@media (max-width: 950px) {
		width: 256px;
		left: -256px;
		position: absolute;
		z-index: 2001;
		transition: all 0.25s;

		&.m-active {
			left: 0;
		}
	}

	@media (max-width: 360px) {
		overflow: auto;

		a {
			padding: 10px 0;
		}
	}
`;

export const BWrap = styled.div`
	flex: 1;
	padding: 0;
	align-items: flex-end;
	display: flex;
	margin-bottom: 64px;

	button {
		padding: 13px 0;
	}

	@media (max-width: 360px) {
		margin-top: 16px;
		margin-bottom: 32px;
	}
`;

// CmsLayout
export const CmsWrapper = styled.div`
	display: flex;
`;

export const CmsMain = styled.main`
	/* height: 100vh; */
	display: flex;
	padding: 32px 0 32px 128px;
	flex-grow: 1;
	flex-wrap: wrap;
	margin-top: 64px;
	align-items: flex-start;
	justify-content: center;

	@media (pointer: coarse), (max-width: 1160px) {
		padding: 32px 0 32px 32px;
	}

	@media (max-width: 950px) {
		padding: 24px;
	}
`;

export const Overlay = styled.div`
	opacity: 0;
	pointer-events: none;
	visibility: hidden;

	@media (max-width: 950px) {
		width: 100%;
		height: 100%;
		position: absolute;
		background-color: rgba(0, 0, 0, 0.4);
		z-index: 2000;
		transition: all 0.25s;
		cursor: pointer;

		&.m-active {
			opacity: 1;
			pointer-events: auto;
			visibility: visible;
		}
	}
`;
