import styled from 'styled-components';
import { color, font } from '../../../../shared/utils/styles';

export const Wrapper = styled.div`
	width: 100%;
	height: calc(100vh - 32px - 64px);
	padding-bottom: 32px;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	@media (max-width: 525px) {
		padding-left: 0;
		padding-right: 24px;
		margin-right: -24px;
		width: calc(100% + 24px);
	}
`;

export const Heading = styled.h2`
	${font.medium};
	align-self: flex-start;
	margin-bottom: 32px;
	padding-left: 20px;

	@media (max-width: 950px) {
		display: none;
	}
`;

export const Table = styled.div`
	width: 100%;
	margin-bottom: 30px;
	max-width: 675px;
	margin-right: auto;
	font-size: 14px;

	select {
		border: none;
		background: transparent;
		text-transform: uppercase;
		letter-spacing: 0;
		font: inherit;
		font-size: 12px;
		width: 65px;
		cursor: pointer;
		transition: opacity 0.25s;
		/* padding: 5px 10px; */

		&:hover {
			opacity: 0.5;
		}

		&.admin {
			color: ${color.success};
		}

		option {
			padding: 10px;
			color: ${color.base};
		}
	}
`;

export const TopBar = styled.div`
	display: flex;
	margin-bottom: 16px;
	justify-content: space-between;
	font-weight: 600;
	padding: 0 20px;
`;

export const Info = styled.div`
	display: flex;
	justify-content: space-between;
	background-color: #fff;
	margin-bottom: 1rem;
	padding: 20px;
	box-shadow: rgba(0, 0, 0, 0.07) 0px 4px 8px;
`;

export const SaveBtn = styled.button`
	background-color: ${color.primary};
	padding: 9px 25px;
	font-size: 14px;
	color: ${color.white};
	border-radius: 27px;
	margin-right: 16px;
	transition: opacity 0.25s;

	&:disabled,
	&:hover {
		opacity: 0.5;
	}
`;
