import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// GraphQl
import { useMutation } from '@apollo/client';
import { RESET_LINK } from '@myvote/common';

// State
import { useError } from '@myvote/common';

// Components
import { Input } from '../../../../shared/components';

// Assets
import logo from '../../../../assets/myvote-logo.svg';

// Styles
import { MainWrap, InnerWrap, Text, BtnWrap } from './style';

// TS Interfaces
import { IError } from '@myvote/common';

const Index = () => {
	const history = useHistory();
	const [sendForgotPasswordEmail] = useMutation(RESET_LINK);

	const [email, setEmail] = useState<string>('');
	const [success, setSuccess] = useState<boolean>(false);

	// Error handling state
	const [errorState, setError] = useError();

	return (
		<MainWrap>
			<InnerWrap>
				<img src={logo} alt='' style={{ marginBottom: 24 }} />
				{!success ? (
					<React.Fragment>
						<Text>
							<h1>Password recovery</h1>
							<p>
								To reset your password, enter your email and click the button
								below.
							</p>
						</Text>
						<Input
							id='email'
							label='Email Address'
							name='email'
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setEmail(e.target.value)
							}
							value={email}
							autoFocus
							required
							error={errorState.emailText !== '' ? true : false}
							errorText={errorState.emailText}
						/>
						<BtnWrap>
							<button className='back' onClick={() => history.push('/')}>
								Back to sign in
							</button>
							<button
								disabled={!email ? true : false}
								onClick={() => {
									sendForgotPasswordEmail({
										variables: {
											email,
										},
									}).then(
										() => {
											setSuccess(true);
										},
										(error: any) => {
											error.graphQLErrors.map(({ message }: any) => {
												try {
													const { error } = JSON.parse(message);

													setError((errorState: IError) => ({
														...errorState,
														emailText: '',
													}));

													error.map((val: any) => {
														if (val.context.key === 'email') {
															setError((errorState: IError) => ({
																...errorState,
																emailText: val.message,
															}));
														}
														return null;
													});
												} catch (e) {
													setError({
														emailText: message,
													});
												}

												return null;
											});
										}
									);
								}}
							>
								Reset
							</button>
						</BtnWrap>
					</React.Fragment>
				) : (
					<React.Fragment>
						<Text>
							<h1>Recovery email sent!</h1>
							<p>
								We've sent you an email containing a link to reset your
								password.
							</p>
						</Text>
						<BtnWrap>
							<div />
							<button
								disabled={!email ? true : false}
								onClick={() => history.push('/')}
							>
								Back to sign in
							</button>
						</BtnWrap>
					</React.Fragment>
				)}
			</InnerWrap>
		</MainWrap>
	);
};

export default Index;
