import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import { logOutUser } from '@myvote/common';

// Components
import { Icon, ModalWrapp } from '../../shared/components';
import Privacy from './PrivacyTerms/Privacy';
import Terms from './PrivacyTerms/Terms';

// Style
import { Wrapper, BWrap } from './style';

// Icons
import { ReactComponent as DashboardIcon } from '../../assets/icons/nav/dashboard-icon.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/nav/users-icon.svg';
import { ReactComponent as CreatePollIcon } from '../../assets/icons/nav/create-poll-icon.svg';
import { ReactComponent as PollListIcon } from '../../assets/icons/nav/poll-list-icon.svg';
import { ReactComponent as AccSettingsIcon } from '../../assets/icons/nav/acc-settings-icon.svg';
import { ReactComponent as PrivacyPolicyIcon } from '../../assets/icons/nav/privacy-policy-icon.svg';
import { ReactComponent as TermsOfUseIcon } from '../../assets/icons/nav/terms-of-use-icon.svg';
import { ReactComponent as LogOutIcon } from '../../assets/icons/nav/log-out-icon.svg';

// TS Interfaces
import { IUser, IUserShort } from '@myvote/common';
interface INav {
	user: IUserShort;
	logOutUser: any;
	open?: boolean;
	hook?: any;
}

const Nav = ({ user, logOutUser, open, hook }: INav) => {
	const [modalIsOpen, setIsOpen] = useState(false);
	const [isPrivacyP, setPrivacyP] = useState(false);

	// Open modal with default Privacy/Terms content
	const openModal = (isPrivacyP: boolean) => {
		setIsOpen(true);
		setPrivacyP(isPrivacyP);
	};

	// Close modal
	const closeModal = () => {
		setIsOpen(false);
	};

	let history = useHistory();

	// Logout
	const logOut = () => {
		document.cookie = 'sid=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
		document.cookie =
			'welcome=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
		logOutUser();
		history.push('/');
	};

	return (
		<Wrapper className={open ? 'm-active' : ''}>
			<span className='email'>{user.email}</span>
			{user.accountType && user.accountType !== 'voter' && (
				<React.Fragment>
					<span className='atitle'>ADMIN TOOLS</span>
					{/* <Icon
						text='Dashboard'
						icon={DashboardIcon}
						svg
						to='/admin/dashboard'
					/> */}
					<Icon text='Users' icon={UserIcon} svg to='/admin/users' />
					<Icon
						text='Create Poll'
						icon={CreatePollIcon}
						svg
						to='/admin/create-poll'
					/>
					<hr />
				</React.Fragment>
			)}

			<Icon text='Poll list' icon={PollListIcon} svg to='/polls-list' />
			<hr />
			<Icon text='Account Settings' icon={AccSettingsIcon} svg to='/profile' />
			<Icon
				text='Privacy Policy'
				icon={PrivacyPolicyIcon}
				svg
				onClick={() => {
					openModal(true);
					hook(false);
				}}
			/>
			<Icon
				text='Terms of Use'
				icon={TermsOfUseIcon}
				svg
				onClick={() => {
					openModal(false);
					hook(false);
				}}
			/>

			<BWrap>
				<Icon text='Log out' icon={LogOutIcon} svg onClick={logOut} />
			</BWrap>

			<ModalWrapp isOpen={modalIsOpen} onRequestClose={closeModal}>
				{isPrivacyP ? <Privacy /> : <Terms />}
			</ModalWrapp>
		</Wrapper>
	);
};

const mapStateToProps = (state: IUser) => ({
	user: state.user,
});

const mapDispatchToProps = {
	logOutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
