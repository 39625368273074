import styled from 'styled-components';
import { color } from '../../../../shared/utils/styles';

export const MainWrap = styled.main`
	top: 50%;
	left: 50%;
	color: black;
	border: 1px solid ${color.secondary};
	padding: 48px 40px;
	position: absolute;
	transform: translate(-50%, -50%);
	box-shadow: 0px 3px 6px #0000000f;
	background-color: white;
	max-width: 448px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;

	@media (max-width: 525px) {
		height: 100%;
		padding: 24px;
	}
`;

export const InnerWrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	form {
		width: 100%;
	}

	img {
		margin-bottom: 32px;
	}

	margin-bottom: 64px;

	@media (max-width: 525px) {
		margin-top: 32px;

		img {
			width: 220px;
			margin-bottom: 64px;
		}
	}

	@media (max-width: 360px) {
		margin-top: 0;
		margin-bottom: 16px;

		img {
			width: 180px;
			margin-bottom: 48px;
		}

		form button {
			padding: 10px 40px;
		}
	}
`;

export const TextWrap = styled.div`
	margin-bottom: 32px;
	width: 100%;

	h1 {
		font-size: 24px;
		line-height: 32px;
		margin-bottom: 8px;
	}
`;

export const BWrap = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 55px;

	label,
	a {
		flex: 1;
		margin: 0;
		position: relative;
		left: -9px;
		& svg path {
			color: ${color.secondary};
		}
	}

	.c-icon:focus {
		opacity: 0.5;
	}

	a {
		color: ${color.primary};
		text-decoration: underline;
		text-align: right;
		border: 0;
		outline: none;

		&:focus {
			opacity: 0.5;
		}
	}

	@media (max-width: 525px) {
		margin-bottom: 32px;
	}

	@media (max-width: 340px) {
		label span,
		a {
			font-size: 14px;
		}
	}
`;

export const RegWrap = styled.div`
	text-align: right;

	a {
		border: 0;
		outline: none;

		span {
			color: ${color.primary};
		}

		&:focus {
			opacity: 0.5;
		}
	}

	@media (max-width: 340px) {
		a {
			font-size: 14px;
		}
	}
`;

export const PText = styled.p`
	font-size: 13px;
	text-align: right;
	align-self: flex-end;

	span,
	a {
		color: ${color.primary};
		cursor: pointer;
		transition: opacity 0.25s;

		&:hover {
			opacity: 0.7;
		}
	}

	@media (max-width: 525px) {
		text-align: center;
		max-width: 186px;
	}
`;
