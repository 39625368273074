import React from 'react';

// Styles
import { LoaderWrap } from './style';

// Icon
import loader from '../../../assets/icons/loader.svg';

const Loader = ({ style }: any) => {
	return (
		<LoaderWrap>
			<img style={style} className='loader' src={loader} alt='loading-icon' />
		</LoaderWrap>
	);
};

export default Loader;
